import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { Utils } from '../../utils/formatting';

const Funds = () => {
    const [crowdfundingCampaigns, setCrowdfundingCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showContributions, setShowContributions] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('user')).token;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        };

        const fetchCrowdfundingCampaigns = async () => {
            try {
                const response = await axios.get('https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/crowdfunds', config);
                setCrowdfundingCampaigns(response.data.crowdfundingCampaigns);
            } catch (error) {
                console.error('Error fetching crowdfunding campaigns:', error);
            }
        };

        fetchCrowdfundingCampaigns();
    }, []);

    const handleSendFunds = async (userId) => {
        try {
            setLoading(true);
            
            const token = JSON.parse(localStorage.getItem('user')).token;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.post('https://buyinbulk-zxvi.onrender.com/api/v1/account/sendfunds', { userId }, config);
            // Add any additional logic after sending funds
        
        } catch (error) {
            console.log(error);
            console.error('Error sending funds:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleContributions = (campaignId) => {
        setShowContributions(prevState => ({
            ...prevState,
            [campaignId]: !prevState[campaignId]
        }));
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = crowdfundingCampaigns.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(crowdfundingCampaigns.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getPaginationItems = () => {
        let pages = [];
        if (totalPages <= 10) {
            // If total pages are less than or equal to 5, show all pages
            pages = [...Array(totalPages).keys()].map(number => number + 1);
        } else {
            // If more than 5 pages, show a subset of pages with ellipses
            if (currentPage <= 3) {
                pages = [1, 2, 3, 4, '...', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
            }
        }
        return pages;
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Crowdfunding Campaigns ({currentItems.length})</h2>
            <ul className="list-group">
                {currentItems.map(campaign => (
                    <motion.li key={campaign._id} className="list-group-item"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='d-flex align-items-center w-100'>
                            <span className='mr-4 p-2'>
                                <img
                                    src={campaign?.product?.productImages[0]}
                                    className='img-fluid rounded'
                                    alt="Product"
                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                />
                            </span>
                            <span>
                                <h3 className="mb-2 font-weight-bold">{campaign.product?.productName}</h3>
                                <p className="mb-1">Description: {Utils.truncateText(campaign.product?.productDescription)}</p>
                                <p className="mb-1">Goal Amount: {Utils.formatPrice(campaign?.goalAmount)}</p>
                                <p className="mb-2">Amount Contributed: {Utils.formatPrice(campaign?.currentAmount)}</p>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className="mb-1">Contributions:</p>
                            <button
                                onClick={() => toggleContributions(campaign?._id)}
                                className={`btn btn-link p-0`}
                            >
                                {showContributions[campaign?._id] ? (<FaChevronCircleUp />) : (<FaChevronCircleDown />)}
                            </button>
                        </div>
                        <motion.ul
                            className="list-group mb-3"
                            initial={false}
                            animate={{ height: showContributions[campaign?._id] ? "auto" : 0 }}
                            transition={{ duration: 0.5, ease: "easeInOut" }}
                            style={{ overflow: "hidden" }}
                        >
                            {campaign.contributions.map(contribution => (
                                <li key={contribution._id} className="list-group-item bg-dark text-light">
                                    <p className="mb-1">User: {contribution?.participant.id.email}</p>
                                    <p className="mb-1 fw-4">Amount: {Utils.formatPrice(contribution?.amountPaid)}</p>
                                    <p className="mb-1">Percentage: {contribution?.percentage}%</p>
                                    <p className="mb-0">Received: {campaign?.contributionsReceived.length > 0 && campaign?.contributionsReceived.some(rec => rec.contributionId === contribution._id) ? 'Yes' : 'No'}</p>
                                </li>
                            ))}
                        </motion.ul>
                        <button
                            onClick={() => handleSendFunds(campaign?.product.owner)}
                            className="btn btn-success w-100"
                            disabled={loading}
                        >
                            {loading ? 'Sending Funds...' : 'Send Funds'}
                        </button>
                    </motion.li>
                ))}
            </ul>
            <nav>
                <ul className="pagination justify-content-center mt-4">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={handlePrevPage} className="page-link">Previous</button>
                    </li>
                    {getPaginationItems().map((item, index) => (
                        <li key={index} className={`page-item ${item === currentPage ? 'active' : ''} ${item === '...' ? 'disabled' : ''}`}>
                            {item === '...' ? (
                                <span className="page-link">...</span>
                            ) : (
                                <button onClick={() => paginate(item)} className="page-link">
                                    {item}
                                </button>
                            )}
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button onClick={handleNextPage} className="page-link">Next</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Funds;
