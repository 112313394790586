import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";

const FProduct = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth.user);

  const [productName, setProductName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [productCategory, setProductCategory] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [weightQuantity, setWeightQuantity] = useState(0);
  const [productDescription, setProductDescription] = useState("");
  const [productStatus, setProductStatus] = useState("In Stock");
  const [update, setUpdate] = useState(false);
  const [productOffer, setProductOffer] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [farmName, setFarmName] = useState("");
  const [productImages, setProductImages] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the image upload

  // Image upload function
  const uploadImages = async (images) => {
    const Cloudinary_Url =
      "https://api.cloudinary.com/v1_1/okpanz/image/upload";

    try {
      setLoading(true);

      const uploadPromises = images.map((image) => {
        const data = new FormData();
        data.append("file", image);
        data.append("upload_preset", "BuynBulk");
        data.append("cloud_name", "okpanz");
        return axios.post(Cloudinary_Url, data);
      });

      const responses = await Promise.all(uploadPromises);
      const imageUrls = responses.map((res) => res.data.secure_url);

      setProductImages((prevImages) => [...prevImages, ...imageUrls]); // Add new URLs to the existing array
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  // Function to add product
  const addProduct = async () => {
    try {
      setUpdate(true);
      const newProduct = await axios.post(
        API_URL + "product/create",
        {
          productName,
          productCategory,
          productDescription,
          productOffer,
          productQtty: weightQuantity,
          productStatus,
          productLocation,
          productPrice,
          productImages,
          farmName,
        },
        { headers: config }
      );
      toast.success("Created Successfully");
      setTimeout(() => {
        navigate("/sidebar/Fdashboard");
      }, 1000);
      setUpdate(false);
    } catch (err) {
      setUpdate(false);
      const errMsg = err.response.data.errors;
      setFormErrors(errMsg);
      toast.error("Check all your inputs and make sure they are correct");
    }
  };

  const categories = ["tubers", "grains", "vegetable", "livestock", "fruits"];

  return (
    <div className="container mt-5">
      <Form className="AddproductInp w-75 mx-auto">
        <h2 className="text-light bg-success p-3 mb-4">Add Products</h2>

        <Form.Select
          onChange={(e) => setProductCategory(e.target.value)}
          className="form-select mb-3"
          aria-label="Default select example"
        >
          <option selected disabled>
            Choose Product Category
          </option>
          {categories.map((category) => (
            <option key={category}>{category}</option>
          ))}
        </Form.Select>

        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setProductName(e.target.value)}
            value={productName}
            type="text"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Product Name"
          />
          <small className="errorMsgg " style={{ color: "red" }}>
            {formErrors.productName}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Product Name
          </Form.Label>
        </Form.Group>

        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setProductPrice(+e.target.value)}
            value={productPrice}
            type="number"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Product Price"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productPrice}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Product Price
          </Form.Label>
        </Form.Group>

        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setWeightQuantity(+e.target.value)}
            value={weightQuantity}
            type="number"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Weight / Quantity"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productQtty}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Weight / Quantity
          </Form.Label>
        </Form.Group>

        <Form.Select
          onChange={(e) => setProductStatus(e.target.value)}
          value={productStatus}
          className="form-select mt-3"
          aria-label="Default select example"
        >
          <option selected disabled>
            Choose Product Status
          </option>
          <option value="In Stock">In Stock</option>
          <option value="Out of Stock">Out of Stock</option>
        </Form.Select>

        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setProductDescription(e.target.value)}
            value={productDescription}
            type="text"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Product Description"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productDescription}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Product Description
          </Form.Label>
        </Form.Group>
        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setFarmName(e.target.value)}
            value={farmName}
            type="text"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Farm Name"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.farmName}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Farm Name
          </Form.Label>
        </Form.Group>
        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setProductOffer(e.target.value)}
            value={productOffer}
            type="text"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Product Offer"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productOffer}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Product Offer
          </Form.Label>
        </Form.Group>

        <Form.Group className="form-floating">
          <Form.Control
            onChange={(e) => setProductLocation(e.target.value)}
            value={productLocation}
            type="text"
            className="form-control mt-2"
            id="floatingInputValue"
            placeholder="Product Location"
          />
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productLocation}
          </small>
          <Form.Label className="my-1" htmlFor="floatingInputValue">
            Product Location
          </Form.Label>
        </Form.Group>
        <div className="mb-3 flex">
          <Form.Label htmlFor="formFileMultiple" className="form-label">
            Upload Product Image
          </Form.Label>
          <span className="d-flex align-items-center">
            <Form.Control
              onChange={(e) => uploadImages([...e.target.files])} // Handle multiple files
              multiple // Allow multiple file selection
              name="file"
              className="form-control"
              type="file"
              id="formFileMultiple"
            />
            {loading && <BeatLoader color="#116530" size={15} />}
          </span>
          <small className="errorMsgg" style={{ color: "red" }}>
            {formErrors.productImages}
          </small>
          <p className="text-muted mt-2">Please upload at least two images.</p>
        </div>

        <Button
          className="btn btn-success d-block mx-auto"
          disabled={update}
          onClick={addProduct}
        >
          {update ? "Updating..." : "Update"}
        </Button>
      </Form>
    </div>
  );
};

export default FProduct;
