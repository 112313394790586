import React from 'react';
import Header from '../Components/Header';
import PaystackButtonWrapper from '../Components/PaystackButton';

const Checkout = (props) => {
  const { location } = props;
  const paymentInfo = location.state && location.state.paymentInfo;

  const handlePaymentSuccess = (response) => {
    // console.log('Payment successful:', response);
    // Add logic here to handle successful payment (e.g., update order status, redirect to success page, etc.)
  };

  const handlePaymentClose = () => {
    // console.log('Payment closed');
    // Add logic here to handle when the payment modal is closed
  };

  return (
    <div>
      <Header />
      <h2>Checkout</h2>
      {/* Access amountPaid from paymentInfo */}
      <PaystackButtonWrapper
        amount={paymentInfo ? paymentInfo.amount : 5000} // Replace with your actual amount or a default value
        email="customer@example.com" // Replace with the customer's email
        onSuccess={handlePaymentSuccess}
        onClose={handlePaymentClose}
      />
    </div>
  );
};

export default Checkout;
