import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useNavigate } from 'react-router-dom';

const PaymentPage = ({ location }) => {
  const navigate = useNavigate();

  // Extract paymentInfo from location state
  const { paymentInfo } = location.state;

  const config = {
    public_key: 'your_flutterwave_public_key',
    tx_ref: paymentInfo.itemId,
    amount: paymentInfo.amount,
    currency: 'NGN',
    payment_options: 'card, mobilemoney, ussd',
    customer: {
      email: 'user@email.com',
      phonenumber: '08123456789',
      name: 'John Doe',
    },
    customizations: {
      title: 'BuynBulk',
      description: 'Payment for Cart Item',
    },
  };

  const callback = (response) => {
    // Check if payment was successful
    if (response.status === 'successful') {
      // Perform actions after successful payment (e.g., update paymentVerified)
      // console.log('Payment successful:', response);
    } else {
      // console.log('Payment not successful:', response);
    }

    // Close the Flutterwave modal
    closePaymentModal();

    // Navigate to a different page (e.g., order confirmation)
    navigate('/order-confirmation');
  };

  return (
    <div>
      <h2>Payment Page</h2>
      <FlutterWaveButton {...config} callback={callback} />
    </div>
  );
};

export default PaymentPage;
