// Pur.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Receipt from "../Components/Reciept";
import { MdClose } from "react-icons/md";
import { Utils } from "../../utils/formatting";
const Pur = ({ item }) => {
  const [products, setProducts] = useState([]);
  const { token } = useSelector((state) => state.auth.user);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/";
  const VERIFY_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/"
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        setLoading(true)
        const res = await axios.get(`${API_URL}paid-products`, config);
        if (Array.isArray(res.data)) {
          setProducts(res.data.paidProducts);
        } else {
          setLoading(false)
          console.error("Response data is not an array:", res.data);
          setProducts(res.data.paidProducts);
        }
      } catch (error) {
        setLoading(false)
        console.error("Error fetching products:", error);
        toast.error("Error fetching products. Please try again later.");
      }
    };
    getProduct();
  }, []);

  const handleReceived = async (product) => {
    try {
      await axios.put(
        `${VERIFY_URL}verify/markReceived/${product.contributionDetails.campaignId}/${product.contributionDetails.contributionId}`,
        {},
        config
      );
      toast.success("Marked as Recieved")
    } catch (error) {
      console.error("Error marking product as received:", error);
      toast.error("Error marking product as received.");
    }
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setModalIsOpen(false);
  };

  const handleGenerateReceipt = (contributionId) => {
    openModal(contributionId);
  };

  return (
    <div className="container mt-5">
      <h3 className="text-center mb-4">Purchased Products</h3>
      <div className="row">
        {/* {loading && <div>
          Loading...
          </div>} */}
        {products.length <= 0 &&
          <div className="">
            You've not made any purchases yet
          </div>
        }
        {products.map((product) => (
          <div key={product.productId} className="col-md-4 mb-4">
            <div className="card h-100">
              <img
                src={product.productImages[0]}
                alt={product.productName}
                className="card-img-top object-fit-cover"
                style={{ height: "200px", objectFit: "cover" }}
              />
              <div className="card-body m-0 pb-5"> 
                <h5 className="card-title">{product.productName}</h5>
                <p className="card-text m-0">
                  <strong>Description: </strong>
                  <span>{Utils.truncateText(product.productDescription)}</span>
                </p>
                <p className="card-text m-0">
                  <strong>Amount Paid:</strong> {formatPrice(product.amountPaid)}
                </p>
                <p className="card-text d-flex m-0">
                  <strong>Contribution Percentage:</strong>{" "}
                  {product.contributionPercentage}%
                </p>
                <p className="card-text m-0 mb-10">
                  <strong>Payment Status:</strong> {product.paymentStatus}
                </p>
                <div className="d-flex justify-content-between position-absolute w-75" style={{ bottom: 10 }}>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => handleReceived(product)}
                  >
                    Mark Received
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => handleGenerateReceipt(product.contributionDetails.contributionId)}
                  >
                    Generate Receipt
                  </button>
                </div>
              </div>
            </div>
          </div>

        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Receipt Modal"
      >
        <button onClick={closeModal} className="btn btn-danger text-light "><MdClose /> </button>
        {selectedProduct && <Receipt contributionId={selectedProduct} />}
      </Modal>
    </div>
  );
};

export default Pur;
