import React, { useState } from 'react';
import Header from '../Components/Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import brand from '../Assets/images/brand.png';

const Pricing = () => {
  const [loading, setLoading] = useState({
    starter: false,
    premium: false,
    enterprise: false,
  });

  const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";

  const handleSubscription = async (plan) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [plan]: true }));

      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(`${API_URL}sub/${plan}`, {}, config);
      // console.log(response.data);

      setLoading((prevLoading) => ({ ...prevLoading, [plan]: false }));

      if (response.data.paymentLink) {
        toast.success(response.data.message);
        window.location.href = response.data.paymentLink;
      } else {
        toast.error("Error generating payment link");
      }
    } catch (error) {
      setLoading((prevLoading) => ({ ...prevLoading, [plan]: false }));
      console.error(`Error handling ${plan} subscription:`, error);
    }
  };

 const plans = [
  {
    id: "1",
    name: "Starter",
    price: "5,000",
    description: "The Starter Pack provides essential tools to help you begin your journey.",
    benefits: "Includes 10 Crowdfund Creations",
  },
  {
    id: "2",
    name: "Premium",
    price: "10,000",
    description: "The Premium Pack offers enhanced features to maximize the value of your projects.",
    benefits: "Includes 15 Crowdfund Creations",
  },
  {
    id: "3",
    name: "Enterprise",
    price: "15,000",
    description: "The Enterprise Pack empowers you to achieve your entrepreneurial goals with extensive resources.",
    benefits: "Includes Unlimited Crowdfund Creations",
  },
];


  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Header activePage={'pricing'} />
      <div className="container ">
        <div className="row">
          {plans.map((plan) => (
            <div key={plan.id} className={`col-md-4 mb-4 text-success `} style={{ backgroundImage: `${brand}` }}>
              <div className="card m-3 shadow-sm" style={{ borderColor: "green", height: "100%" }}>
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="card-title text-center">{plan.name}</h2>
                    <p className="card-text text-center text-success">
                      <small className="text-muted fst-italic fw-normal ">{plan.description}</small>
                    </p>
                    <ul className="list-group list-group-flush">
                      <li className='list-group-item text-success'>{plan.benefits}</li>
                      <li className="list-group-item text-success">Basic Features</li>
                      <li className="list-group-item text-success">Customer Support</li>
                      <li className="list-group-item text-success">Training/Resources</li>
                    </ul>
                  </div>
                  <div>
                    <p className="card-price d-flex align-items-center justify-content-center text-success fs-4 opacity-3">
                      <span className='fs-6 '>&#x20A6;</span>
                      <span className='fs-2 fw-bold'>{plan.price}</span>/
                      <span className='fs-6 ml-1'>month</span>
                    </p>
                    <span className='d-flex container justify-content-center'>
                      <button
                        className={`btn btn-outline-success fs-6 ${loading[plan.name.toLowerCase()] ? 'disabled' : ''}`}
                        onClick={() => handleSubscription(plan.name.toLowerCase())}
                      >
                        {loading[plan.name.toLowerCase()] ? `Loading ${plan.name}` : `Get Started with ${plan.name}`}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
