import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import './Receipt.css';

const Receipt = ({ contributionId }) => {
  const [receipt, setReceipt] = useState(null);

  useEffect(() => {
    const fetchReceipt = async () => {
      try {
        const token = JSON.parse(localStorage.getItem('user')).token;
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        };
        const response = await axios.get(`https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/receipt/contribution/${contributionId}`, config);
        setReceipt(response.data);
      } catch (error) {
        console.error("Error fetching receipt:", error);
      }
    };

    fetchReceipt();
  }, [contributionId]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-NG', {
      timeZone: 'Africa/Lagos',
    });
  };

  const downloadReceipt = () => {
  const element = document.getElementById("receipt-container");
  
  // Delay to ensure images are loaded
  setTimeout(() => {
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `receipt-${receipt.itemToCheck.contributionId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: [8.27, 11.69], orientation: 'portrait' }
    };
    
    html2pdf().from(element).set(options).save();
  }, 1000); // Adjust delay if needed
};


  const printReceipt = () => {
    window.print();
  };

  if (!receipt) {
    return <div>Loading...</div>;
  }

  const { itemToCheck, product, userProfile, userInfo } = receipt;
  const currentDate = new Date();

  return (
    <div className="receipt-font">
      <div>
        <div className="container bootstrap snippets bootdeys">
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default invoice" id="receipt-container">
                <div className="invoice-contain" style={{ background: '' }}>
                  <div className="d-flex justify-content-between  h-25">
                    <div>
                      <p className="fw-bolder fs-3" style={{ color: "#121722", marginBottom: "2rem", marginTop: "1.2rem" }}>Invoice</p>
                      <div className="client-info">
                        <p className="fw-medium receipt-for">For : </p>
                        <strong className="fs-3 fw-semibold">{userInfo?.firstName} {userInfo?.lastName}</strong>
                        <h6> Address/Contact: {userProfile?.address} / {userProfile?.phone}</h6>
                      </div>
                    </div>
                    <div className="invoice-no" style={{ marginBottom: "2rem", marginTop: ".5rem" }}>
                      <h4 className="mb-3">Invoice No <br /> <span>{itemToCheck.contributionId}</span></h4>
                      <h4 className="mb-3">Generated at: <br /><span>{currentDate.toLocaleString()}</span> </h4>
                      <h4 className="mb-3">Payment Status: <br /><span className={`badge ${itemToCheck?.paymentStatus === 'Paid' ? 'bg-success text-white fs-6' : 'bg-warning text-white fs-6'}`}>{itemToCheck?.paymentStatus}</span> </h4>
                    </div>
                  </div>
                </div>
                <div className="pricing-section text-center" style={{ marginTop: "1.5rem" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Image</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Campaign Full Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{itemToCheck.product.name}</td>
                        <td>
                          <img src={itemToCheck.product.images[0]} alt={product.name} className="img-responsive" width={60} />
                        </td>
                        <td>{itemToCheck.contributionPercentage}</td>
                        <td>{formatPrice(itemToCheck.amountPaid)}</td>
                        <td>{formatPrice(product.productPrice)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="total d-flex justify-content-between w-25 p-4 rounded align-items-center" style={{ marginTop: "2rem", backgroundColor: "#F2F5F9" }}>
                    <p>Total (NGN) </p> <p className="fw-bolder ">{formatPrice(itemToCheck.amountPaid)}</p>
                  </div>
                </div>
                <footer>
                  <img src="https://res.cloudinary.com/okpanz/image/upload/v1703836081/BuynBulk_m5picq.png" alt="BuynBulk Logo" className="logo" width={100} />
                  <div className="container text-left " style={{fontSize: "12px"}}>
                    <div className="row align-items-start">
                      <div className="col">
                        <div className="col-xs-4 from">
                          <p className="fw-bolder fs-3">BuynBulk</p>
                          <p>Address / Contact Info</p>
                          <p className="mb-1">3rd Floor TAEN Complex Opposite Old Nitel Office</p>
                          <p className="mb-1">Jos, Nigeria 930103</p>
                          <p className="mb-1">Phone: 234-816-4482-158</p>
                          <p>Email: buynbulk22@gmail.com</p>
                        </div>
                      </div>
                      <div className="col">
                        <h5 style={{ marginTop: "2rem" }}>Collection Information</h5>
                        <p>
                          When the crowdfund is complete, the address of the pick-up point will be rendered on the receipt.
                          All valid crowdfunders should come to the pick-up office with their receipt to verify their purchase.
                          The percentage purchased will be given to them upon verification.
                        </p>
                      </div>
                      <div className="col text-right">
                        <h5 style={{ marginTop: "2rem" }}>Thank You!</h5>
                        <p>
                          Thank you, {userProfile?.firstName} {userProfile?.lastName} for using <strong>BuynBulk</strong>, we hope to see your next time and give you best service
                        </p>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
          <div className="row no-print">
            <div className="col-xs-6 margintop">
              <p className="lead marginbottom">THANK YOU!</p>
              <button className="btn btn-success" id="invoice-print" onClick={downloadReceipt}>
                <i className="fa fa-download"></i> Download Receipt
              </button>
              <button className="btn btn-success" id="invoice-print" onClick={printReceipt}>
                <i className="fa fa-print"></i> Print Receipt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
