import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartProvider } from "react-use-cart";
import { useSelector } from "react-redux";

// Import your components and pages
import Header from "./Components/Header";
import Footer from "./Components/footer";
import FarmerRoutes from "./AdminFarmer/FarmerRoutes";
import { ModifyLogin } from "./context";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import SignInTypePage from "./pages/SignInTypePage";
import SignUpForm from "./pages/SignUpForm";
import BSignIn from "./pages/BSignIn";
import FSignIn from "./pages/FSignIn";
import Mail from "./pages/Mail";
import Shop from "./pages/Shop";
import DisplayProduct from "./AdminFarmer/Pages/FProducts";
import Checkout from "./pages/Checkout";
import SingleItem from "./pages/SingleItem";
import Cart from "./pages/Cart";
import PaymentPage from "./pages/PaymentPage";
import PrivateRoute from "./PrivateRoute";
import Pricing from "./pages/Pricing";
import PaymentSuccess from "./pages/PaymentSuccess";
import ProductPurchase from "./pages/ProductPurchase";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";

function App() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="App">
      <BrowserRouter>
        <GoogleOAuthProvider clientId="295048256848-b6nppmgqqqavhes213pv48iprvc1avra.apps.googleusercontent.com">
          <FarmerRoutes />
          <ModifyLogin>
            <CartProvider>
              <Routes>
                {/* Routes to components */}
                <Route path="/header" element={<Header />} />
                <Route path="/footer" element={<Footer />} />

                <Route path="/about" element={<AboutUsPage />} />

                {/* Routes to pages */}
                <Route path="/" element={<HomePage />} />
                <Route path="/contact" element={<ContactUsPage />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route
                  path="/signInAs"
                  element={<PrivateRoute element={SignInTypePage} />}
                />
                <Route
                  path="/signUpForm"
                  element={<PrivateRoute element={SignUpForm} />}
                />
                <Route path="/BSignIn" element={<BSignIn />} />
                <Route path="/change-password" element={<ResetPassword />} />
                <Route path="/reset-password" element={<UpdatePassword />} />
                <Route path="/FSignIn" element={<FSignIn />} />
                <Route path="/mail" element={<Mail />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/success" element={<PaymentSuccess />} />
                <Route path="/succes" element={<ProductPurchase />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/singleItem/:id" element={<SingleItem />} />
              </Routes>
            </CartProvider>
            <ToastContainer />
          </ModifyLogin>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
