import { configureStore } from "@reduxjs/toolkit";
// import { composeWithDevTools } from 'redux-devtools-extension';
import crowdfundReducer from './products/CrowdfundSlice.js'
// reducers
import authReducer from "./auth/auth.slice";
import messagesReducer from "./messages/message";
import {productFetch} from "./products/CrowdfundSlice"

// reducers
const reducers = {
    auth: authReducer,
    message: messagesReducer,
    crowdfund : crowdfundReducer
    
};
// store.dispatch(productFetch())
const store = configureStore({
    reducer: reducers,
    devTools: true
})
export default store;