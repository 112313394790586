import React from "react";
import { GiWeight } from "react-icons/gi";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ShopCard = ({ item }) => {
  const navigate = useNavigate();

  // Format the price
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div
      className="card shop-card shadow-sm"
      style={{
        width: "260px",
        height: "350px",
        cursor: "pointer",
        margin: "5px",
      }}
      onClick={() => navigate(`/singleItem/${item._id}`)}
    >
      <div className="overflow-hidden" style={{ height: "60%" }}>
        <img
          src={item.productImages[0]}
          alt={item.productName}
          className="card-img-top shop-card-image"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="card-body d-flex flex-column justify-content-between">
        <div>
          <h5
            className="card-title mb-2 text-truncate"
            title={item.productName}
          >
            {item.productName}
          </h5>
          <p className="card-text mb-1 d-flex align-items-center">
            <GiWeight className="me-2" /> {item.productQtty}
          </p>
          <p className="card-text mb-3 fs-6 d-flex align-items-center">
            <MdLocationOn className="me-2" /> {item.productLocation}
          </p>
        </div>
        <h3 className="card-price">{formatPrice(item.productPrice)}</h3>
      </div>
      <style>
        {`
          .shop-card:hover {
            transform: translateY(-5px);
            transition: transform 0.3s;
          }
          .card-price {
            color: #116530;
            font-weight: bold;
          }
          .card-title {
            font-size: 1.1rem;
            font-weight: bold;
          }
        `}
      </style>
    </div>
  );
};

export default ShopCard;
