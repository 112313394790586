import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bankCodes from '../Components/data/bankCodes.json';
import './CreateAccount.css'; // Import custom CSS file for styling

const CreateAccount = () => {
    const { token } = useSelector((state) => state.auth.user);
    const API_URL = 'https://buyinbulk-zxvi.onrender.com/api/v1/';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    };

    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!accountNumber || !bankName || !accountName) {
            setError('Please fill in all fields.');
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_URL}/account`,
                { accountNumber, bankName, accountName },
                config
            );
            setLoading(false);
            toast.success('Account created successfully.')
            
            setAccountNumber('');
            setBankName('');
            setAccountName('');
            setError('');
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data);
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <h2 className="mb-4">Upload Account Details</h2>
                    {error && <Alert variant="danger" className="mb-3">{error}</Alert>}
                    <Form onSubmit={handleRegister}>
                        <Form.Group controlId="accountNumber">
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="bankName">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                                as="select"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                                className="custom-select green-select" 
                                required
                            >
                                <option value="">Select Bank</option>
                                {bankCodes[0].banks
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((bank) => (
                                        <option key={bank.id} value={bank.code}>{bank.name}</option>
                                    ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="accountName">
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-100 my-2 btn btn-outline-success text-light font-bold " 
                            style={{backgroundColor: "#116530"}}
                        >
                            {loading ? 'Uploading Account Details...' : 'Upload Account Details'}
                        </button>
                    </Form>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default CreateAccount;
