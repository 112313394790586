import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { FaWindowClose } from "react-icons/fa";
import { CiSquareMore } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { Utils } from "../../utils/formatting";
Modal.setAppElement(document.body);

const DisplayProduct = () => {
  const [loadingStates, setLoadingStates] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [crowdLoading, setCrowdLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [goalAndCrowdfundRendered, setGoalAndCrowdfundRendered] =
    useState(false);
  const [selectedProductStatus, setSelectedProductStatus] = useState(null);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const truncateText = (text, wordLimit = 20) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const fetchProducts = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;

      if (!token) {
        toast.error("Token not available. Please log in.");
        return;
      }

      const response = await fetch(
        "https://buyinbulk-zxvi.onrender.com/api/v1/product/getProductsMe",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch products:", response.statusText);
        toast.error("Failed to fetch products. Please try again.");
        return;
      }

      const data = await response.json();

      if (Array.isArray(data.data)) {
        const productsWithStatus = await Promise.all(
          data.data.map(async (product) => {
            const statusResponse = await fetch(
              `https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/status/${product._id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (statusResponse.ok) {
              const statusData = await statusResponse.json();
              return { ...product, crowdfundingStatus: statusData };
            } else {
              console.error(
                `Failed to fetch crowdfunding status for product ${product._id}:`,
                statusResponse.statusText
              );
              toast.error(
                `Failed to fetch crowdfunding status for product ${product._id}. Please try again.`
              );
              return product;
            }
          })
        );

        setProducts(productsWithStatus);
      } else {
        console.error("Invalid products data format:", data.data);
        toast.error("Invalid products data format. Please try again.");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
      toast.error("Error fetching products. Please try again.");
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  const handleOpenModal = async (e, productId) => {
    e.stopPropagation();
    // console.log("Product ID clicked:", productId);

    const selectedProduct = products.find((p) => p._id === productId);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user ? user.token : null;

    const statusResponse = await fetch(
      `https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/status/${productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (statusResponse.ok) {
      const statusData = await statusResponse.json();
      setSelectedProductStatus(statusData);
    } else {
      console.error(
        `Failed to fetch crowdfunding status for product ${productId}:`,
        statusResponse.statusText
      );
      toast.error(
        `Failed to fetch crowdfunding status for product ${productId}. Please try again.`
      );
    }

    setModalIsOpen(true);
  };

  const handleStartCampaign = async (productId) => {
    try {
      // Set loading state for this product to true
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [productId]: true,
      }));

      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;

      if (!token) {
        toast.error("Token not available. Please log in.");
        return;
      }

      const selectedProduct = products.find(
        (product) => product._id === productId
      );

      const startResponse = await fetch(
        "https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/start",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            productId: productId,
            creator: user.responseBody.userId,
            goalAmount: selectedProduct.productPrice,
          }),
        }
      );

      if (!startResponse.ok) {
        if (startResponse.status === 403) {
          // console.log(startResponse)
          setTimeout(() => {
            toast.error(
              "Oops, your subscription has expired. Redirecting you to renew..."
            );
          }, 2000);
          // navigate("/pricing");
        } else {
          // console.log(startResponse);
          toast.error(
            "Failed to start crowdfunding campaign. Please try again."
          );
        }
        return;
      }

      toast.success("Crowdfunding campaign started successfully.");
      fetchProducts();
    } catch (error) {
      console.log(error);
      console.error("Error starting crowdfunding campaign:", error);
      toast.error("Error starting crowdfunding campaign. Please try again.");
    } finally {
      // Set loading state for this product back to false
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [productId]: false,
      }));
    }
  };
  const handleDeleteProduct = async (productId) => {
    const productToDelete = products.find(
      (product) => product._id === productId
    );

    // Check if the product has an active campaign
    if (
      productToDelete.crowdfundingStatus &&
      productToDelete.crowdfundingStatus.activeCampaign
    ) {
      // Display toast message indicating the product cannot be deleted
      toast.error("Cannot delete product with active campaign.");
      return;
    }

    setProducts((prevProducts) => {
      return prevProducts.map((product) =>
        product._id === productId
          ? { ...product, deleteLoading: true }
          : product
      );
    });

    const user = JSON.parse(localStorage.getItem("user"));
    const token = user ? user.token : null;

    try {
      const response = await fetch(
        `https://buyinbulk-zxvi.onrender.com/api/v1/product/deleteProduct/${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to delete product ${productId}:`,
          response.statusText
        );
        toast.error(`Failed to delete product ${productId}. Please try again.`);
        setProducts((prevProducts) => {
          return prevProducts.map((product) =>
            product._id === productId
              ? { ...product, deleteLoading: false }
              : product
          );
        });
        return;
      }

      setProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== productId)
      );
      toast.success(`Product ${productId} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting product ${productId}:`, error);
      toast.error(`Error deleting product ${productId}. Please try again.`);
    }
  };

  const sent = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;

      if (!token) {
        toast.error("Token not available. Please log in.");
        return;
      }

      const campaignId = selectedProductStatus.activeCampaign._id;
      // console.log(campaignId);

      const response = await axios.put(
        `https://buyinbulk-zxvi.onrender.com/api/v1/verify/markSent/${campaignId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response);
      toast.success("Campaign marked as sent successfully.");
    } catch (error) {
      console.error("Error marking campaign as sent:", error);
      toast.error("Error marking campaign as sent. Please try again.");
    }
  };
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user ? user.token : null;

        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(
          "https://buyinbulk-zxvi.onrender.com/api/v1/count/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCount(response.data);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
  }, []);

  // console.log("counts are::", count);
  useEffect(() => {
    if (selectedProductStatus && !goalAndCrowdfundRendered) {
      setGoalAndCrowdfundRendered(true);
    }
  }, [selectedProductStatus, goalAndCrowdfundRendered]);

  if (loading) {
    return <p className="text-center text-gray-600">Loading...</p>;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(products.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const getPaginationItems = () => {
    let pages = [];
    if (totalPages <= 6) {
      // If total pages are less than or equal to 5, show all pages
      pages = [...Array(totalPages).keys()].map((number) => number + 1);
    } else {
      // If more than 5 pages, show a subset of pages with ellipses
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, "...", totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        pages = [
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages,
        ];
      }
    }
    return pages;
  };
  return (
    <div className="container mt-4">
      <div className="d-md-flex   justify-content-around gap-3">
        <div
          className="card text-white my-2 shadow"
          style={{
            width: "100%",
            height: "150px",
            backgroundColor: "#d5d905",
            cursor: "pointer",
          }}
        >
          <div className="card-body d-flex align-items-center justify-content-between">
            <i className="fas fa-box fa-2x me-2 text-success"></i>
            <h2 className="fs-6">
              Total Products <br /> {count.productCount}
            </h2>
          </div>
        </div>
        <div
          className="card text-white bg-success my-2 shadow"
          style={{ width: "100%", height: "150px", cursor: "pointer" }}
        >
          <div className="card-body d-flex align-items-center justify-content-between">
            <i
              className="fas fa-bullhorn fa-2x me-2 text-center "
              style={{ color: "#d5d905" }}
            ></i>
            <span className="text-center">
              Active Campaign <br />
              {count.activeCampaign}
            </span>
          </div>
        </div>
      </div>
      <h1 className="text-2xl font-bold mb-4">Your Products</h1>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {currentItems.length === 0 ? (
          <p className="text-center text-gray-600">No products available.</p>
        ) : (
          currentItems.map((product) => (
            <div key={product._id} className="col mb-4">
              <div className="card h-100 overflow-hidden">
                <img
                  src={product.productImages[0]}
                  alt={product.productName}
                  className="card-img-top object-fit-cover"
                  style={{ height: "180px", objectFit: "cover" }}
                />
                <div className="card-body d-flex flex-column justify-content-between">
                  <h2
                    className="card-title text-left"
                    style={{
                      margin: "0",
                      marginBottom: "0",
                      textAlign: "left",
                    }}
                  >
                    {product.productName}
                  </h2>
                  <p
                    className="card-text"
                    style={{ margin: "0", marginBottom: "0" }}
                  >
                    {truncateText(product.productDescription)}
                  </p>
                  <p
                    className="text-success font-weight-bold"
                    style={{ margin: "0" }}
                  >
                    Price: {Utils.formatPrice(product.productPrice)}
                  </p>

                  {product.crowdfundingStatus ? (
                    <div>
                      <p style={{ margin: "0", marginBottom: "0" }}>
                        Crowdfunding Enabled:{" "}
                        {product.crowdfundingStatus.crowdfundingEnabled.toString()}
                      </p>
                      {product.crowdfundingStatus.activeCampaign && (
                        <p className="d-flex align-items-center">
                          <span className="me-2">View Campaign:</span>
                          <CiSquareMore
                            className="fs-4 border-2 text-success"
                            style={{
                              cursor: "pointer",
                              transition: "transform 0.5s ease-in-out",
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Click to view Campaign information"
                            onClick={(event) =>
                              handleOpenModal(event, product._id)
                            }
                          />
                        </p>
                      )}
                    </div>
                  ) : (
                    <p>Crowdfunding status not available.</p>
                  )}
                  <div className="mx-auto w-100">
                    {!product.crowdfundingStatus ||
                    !product.crowdfundingStatus.crowdfundingEnabled ? (
                      <button
                        onClick={() => handleStartCampaign(product._id)}
                        className="btn btn-primary btn-sm mx-1"
                      >
                        {crowdLoading ? "Loading..." : "Start Crowdfund"}
                      </button>
                    ) : null}
                    <button
                      onClick={() => handleDeleteProduct(product._id)}
                      className="btn btn-danger btn-sm mx-1"
                    >
                      {product.deleteLoading
                        ? "Deleting Product ..."
                        : "Delete Product"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <nav>
        <ul className="pagination justify-content-end mt-4">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button onClick={handlePrevPage} className="page-link">
              Previous
            </button>
          </li>
          {getPaginationItems().map((item, index) => (
            <li
              key={index}
              className={`page-item ${item === currentPage ? "active" : ""} ${
                item === "..." ? "disabled" : ""
              }`}
            >
              {item === "..." ? (
                <span className="page-link">...</span>
              ) : (
                <button onClick={() => paginate(item)} className="page-link">
                  {item}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button onClick={handleNextPage} className="page-link">
              Next
            </button>
          </li>
        </ul>
      </nav>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Active Campaign Modal"
      >
        <h2>Active Campaign Details</h2>
        {selectedProductStatus && (
          <div>
            <p>
              Goal Price:{" "}
              <span className="text-success">
                {Utils.formatPrice(
                  selectedProductStatus.activeCampaign.goalAmount
                )}
              </span>
            </p>
            <p>
              Total Crowdfund:{" "}
              <span className="text-success">
                {Utils.formatPrice(
                  selectedProductStatus.activeCampaign.currentAmount
                )}
              </span>
            </p>
            {selectedProductStatus &&
              selectedProductStatus.activeCampaign.contributions && (
                <div>
                  <h3>Contributions:</h3>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Participant</th>
                        <th>Amount Paid</th>
                        <th>Percentage</th>
                        <th>Payment Status</th>
                        <th>Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProductStatus.activeCampaign.contributions.map(
                        (contribution) => (
                          <tr key={contribution._id}>
                            <td>{contribution.participant.email}</td>
                            <td>
                              {Utils.formatPrice(contribution.amountPaid)}
                            </td>
                            <td>{contribution.percentage}%</td>
                            <td>{contribution.paymentStatus}</td>
                            <td>
                              {selectedProductStatus.activeCampaign.contributionsReceived.some(
                                (rec) => rec.contributionId === contribution._id
                              )
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        )}
        <button
          onClick={sent}
          className="btn btn-success btn-sm mx-1"
          disabled={
            selectedProductStatus &&
            selectedProductStatus.activeCampaign &&
            selectedProductStatus.activeCampaign.sent === true
          }
        >
          {crowdLoading ? (
            "Sending..."
          ) : (
            <span className="d-flex align-items-center">
              Sent <IoCheckmarkDoneCircle />
            </span>
          )}
        </button>

        <button
          onClick={handleCloseModal}
          className="btn btn-danger position-absolute bottom-0 end-0 m-3"
        >
          Close <FaWindowClose />
        </button>
      </Modal>
    </div>
  );
};

export default DisplayProduct;
