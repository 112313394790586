import "../Assets/css/pages Css/Shop.css";
import ShopCard from "../Components/ShopCard";
import axios from "axios";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { BiSearchAlt2 } from "react-icons/bi";
import Header from "../Components/Header";
import { MoonLoader } from "react-spinners";
import Pagination from "react-bootstrap/Pagination";

const Shop = () => {
  const [allProd, setAllProd] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(25);

  const getAll = async () => {
    try {
      const res = await axios.get(
        "https://buyinbulk-zxvi.onrender.com/api/v1/getall/all"
      );
      setAllProd(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    document.title= "BuynBulk | Shop"
    getAll();
  }, []);

  const getFilteredProd = () => {
    if (!query) {
      return allProd;
    }
    return allProd.filter((prod) =>
      prod.productName.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredProd = getFilteredProd();

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProd.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header activePage={"shop"} />
      <Container className="my-5">
        <div className="shop-topSection d-block d-md-flex">
          <h2 className="topicWL mb-5">Categories</h2>
          <Form className="shop_form">
            <div className="shop_search_section">
              <Form.Control
                type="search"
                onChange={handleSearch}
                className="w-100 shop_search_bar outline-success"
              />
              <div className="shop_search_icon">
                <BiSearchAlt2 className="search_icon" />
              </div>
            </div>
          </Form>
        </div>
      </Container>
      <div className="shop_mapped_items">
        {loading ? (
          <MoonLoader className="mt-5" color="#116530" size="75px" />
        ) : currentProducts.length > 0 ? (
          currentProducts.map((prod) => <ShopCard key={prod._id} item={prod} />)
        ) : (
          <h3>No items found</h3>
        )}
      </div>
      {filteredProd.length > productsPerPage && (
        <Container className="my-5">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => paginate(currentPage - 1)}
            />
            {currentPage > 2 && (
              <>
                <Pagination.Item onClick={() => paginate(1)}>1</Pagination.Item>
                <Pagination.Ellipsis />
              </>
            )}
            {Array.from(
              { length: Math.ceil(filteredProd.length / productsPerPage) },
              (_, i) => i + 1
            ).map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            {currentPage <
              Math.ceil(filteredProd.length / productsPerPage) - 1 && (
              <>
                <Pagination.Ellipsis />
                <Pagination.Item
                  onClick={() =>
                    paginate(Math.ceil(filteredProd.length / productsPerPage))
                  }
                >
                  {Math.ceil(filteredProd.length / productsPerPage)}
                </Pagination.Item>
              </>
            )}
            <Pagination.Next
              disabled={
                currentPage === Math.ceil(filteredProd.length / productsPerPage)
              }
              onClick={() => paginate(currentPage + 1)}
            />
          </Pagination>
        </Container>
      )}
    </>
  );
};

export default Shop;
