import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./Pages/Dashboard";
import SideBar from "./Components/SideBar";
import TopNav from "./Components/TopNav";
import FProfile from "./Pages/Profile";
import FCategory from "./Pages/FCategory";
import DisplayProduct from "./Pages/FProducts";
import FProduct from "./Pages/FProduct";
import EditProfile from "./Pages/FProfile";
import Purchased from "./Components/purchased";
import Funds from "./Components/Funds";
import CreateAccount from "./Components/CreateAccount";
import Receipt from "./Components/Reciept";
import PrivateRoute from "./PrivateRoutes";
import UserTable from "./Components/AllUsers";

const FarmerRoutes = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route
          path="/sidebar/*"
          element={
            <SideBar>
              <TopNav />
              <Routes>
                <Route
                  path="Fdashboard"
                  element={<PrivateRoute element={Dashboard} />}
                />
                <Route
                  path="Edit"
                  element={<PrivateRoute element={EditProfile} />}
                />
                <Route
                  path="purchased"
                  element={<PrivateRoute element={Purchased} />}
                />
                <Route
                  path="FCategory"
                  element={<PrivateRoute element={FCategory} />}
                />
                <Route
                  path="view-product"
                  element={<PrivateRoute element={DisplayProduct} />}
                />
                <Route
                  path="FProduct"
                  element={<PrivateRoute element={FProduct} />}
                />
                <Route
                  path="users"
                  element={<PrivateRoute element={UserTable} />}
                />
                <Route
                  path="FProfile"
                  element={<PrivateRoute element={FProfile} />}
                />
                <Route
                  path="funds"
                  element={<PrivateRoute element={Funds} />}
                />
                <Route
                  path="acctupload"
                  element={<PrivateRoute element={CreateAccount} />}
                />
                <Route
                  path="receipt"
                  element={<PrivateRoute element={Receipt} />}
                />
              </Routes>
            </SideBar>
          }
        />
      </Routes>
    </>
  );
};

export default FarmerRoutes;
