import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack';
import './PaystackButtonWrapper.css'; // Import a CSS file for styling

const PaystackButtonWrapper = ({ amount, email, onSuccess, onClose }) => {
  const publicKey = 'pk_test_a00bbe4c333d15f715b8c36a3b9281075c6d0046'; // Your Paystack public key

  const [key, setKey] = useState(publicKey);
  const [text, setText] = useState('Pay with Paystack');
  const [paystackProps, setPaystackProps] = useState({
    email,
    amount: amount * 100, // Paystack amount is in kobo, so multiply by 100
    publicKey: key,
    text,
    onSuccess,
    onClose,
  });

  const handlePayment = () => {
    // You can add logic here to update the amount, email, or any other details dynamically
    setPaystackProps({
      ...paystackProps,
      amount: amount * 100,
      email,
      publicKey: key,
    });
  };

  return (
    <div className="centered-container">
      <div className="paystack-button-wrapper">
        <PaystackButton {...paystackProps} callback={handlePayment} />
      </div>
    </div>
  );
};

export default PaystackButtonWrapper;
