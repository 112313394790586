import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { updatePassword } from "../redux/auth/auth.slice"; 

const UpdatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(decodeURIComponent(location.search));
  const token = searchParams.get("token");

  useEffect(() => {
    document.title = "BuynBulk | Update Password";
  }, []);
  // console.log(token);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const res = await dispatch(updatePassword({ token, newPassword }));
      if (!res.error) {
        setLoading(false);
        toast.success("Password updated successfully!");
        navigate("/signInAs"); // Redirect to sign-in page 
      } else {
        console.log(res.error);
        toast.error("Failed to update password");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
      setLoading(false);
    }
  };

  return (
    <div className="signIn-page">
      <Row>
        <Col md="6" className="leftSide">
          <Container>
            <div className="left-signIn-page">
              <h3 className="brand mt-2">
                <Link to="/">BuynBulk</Link>
              </h3>
            </div>
            <div className="left-text">
              <h2>Update Your Password</h2>
              <p>Set a new password for your account</p>
            </div>
          </Container>
        </Col>
        <Col sm="12" md="6" className="right-side">
          <Container className="w-sm-100 w-md-75">
            <div className="right-signIn-page">
              <div className="panel-body">
                <Form onSubmit={handleSubmit}>
                  <fieldset>
                    <h2 className="mb-3 cl-h2">Update Password</h2>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <div className="input-group my-3">
                            <span className="input-group-addon">
                              <i className="glyphicon glyphicon-lock"></i>
                            </span>
                            <Form.Control
                              className="form-control"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              placeholder="New Password"
                              name="newPassword"
                              required
                              type="password"
                              minLength={6}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group>
                          <div className="input-group my-3">
                            <span className="input-group-addon">
                              <i className="glyphicon glyphicon-lock"></i>
                            </span>
                            <Form.Control
                              className="form-control"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              required
                              type="password"
                              minLength={6}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group>
                          {!loading ? (
                            <Button
                              type="submit"
                              className="my-4 btn btn-lg btn-success btn-block fw-normal text-light w-100"
                            >
                              Update Password
                            </Button>
                          ) : (
                            <Button
                              disabled
                              className="my-4 btn btn-lg btn-success btn-block fw-normal text-light w-100"
                            >
                              Updating...
                            </Button>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Form>
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatePassword;
