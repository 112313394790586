import "../Assets/css/pages Css/SignUpForm.css";
import Header from "../Components/Header";
import { toast } from "react-toastify";
import axios from "axios";
import SignInTypeImg from "../Assets/images/Group 242.png";
import SignInTypeImgFloatMid from "../Assets/images/Group 245.png";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

//css for signInTypePage
import "../Assets/css/pages Css/SignInTypePage.css";

//react-bootstrap imports
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { useDispatch } from "react-redux";
import { registerBuyer, registerFarmer } from "../redux/auth/auth.slice";
import GoogleOAuthButton from "../Components/GoogleOAuthButton";

const SignUpForm = () => {
  const navigate = useNavigate();

  const [who, setWho] = useState();
  const [loading, setLoading] = useState();
  const [pwdErr, setPwdErr] = useState();
  const dispatch = useDispatch();

  const [firstName, setfirstName] = useState("");
  const [phone, setphone] = useState("");
  const [pwd, setpwd] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [pwd2, setpwd2] = useState("");

  const farmerInstance2 = {
    password: pwd,
    lastName: lastName,
    firstName: firstName,
    email: email,
    password2: pwd2,
  };
  const buyerInstance2 = {
    lastName,
    firstName,
    email,
    password: pwd,
    password2: pwd2,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (pwd !== pwd2) {
        setPwdErr(true);
        setTimeout(() => {
          toast.error("Passwords are not the same");
          setPwdErr(false);
        }, 200);
        return;
      }
      if (who === "buyer") {
        setLoading(true);
        const res = await dispatch(registerBuyer(buyerInstance2));
        setResponse(res?.payload?.data?.message);
        setLoading(false);
        if (!res.error) {
          handleShow();
        } else {
          // console.log(res.payload);
          const errors = Object.entries(res?.payload).map(([k, v]) => v);
          throw Error(errors);
        }
      } else {
        setLoading(true);

        const res = await dispatch(registerFarmer(farmerInstance2));
        setResponse(res?.payload?.data?.message);
        setLoading(false);
        if (!res.error) {
          handleShow();
        } else {
          // console.log(res.payload);
          const errors = Object.entries(res?.payload).map(([k, v]) => v);
          throw Error(errors);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const [response, setResponse] = useState();
  // for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-success">Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>{response}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => navigate(`/mail?email=${email}&password=${pwd}`)}
          >
            Go to mail
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="signInTypePage">
        <div className="green-bg"></div>
        <img
          src={SignInTypeImgFloatMid}
          className="SignInTypeImgFloatMid"
          alt="floating-diag"
        />
        <Header />
        <Row className="row-signInTypePage">
          <Col xs="12" lg="6" className="type-section-a-col">
            <Container>
              <section className="type-section-a text-center mt-1 mb-3 pb-1 ps-3">
                <h2 className="text-start">Welcome to BuynBulk</h2>
                <p className="text-start">Lets get you started</p>
              </section>
              <div className="status-type-btn d-flex justify-content-center gap-5">
                <Button
                  variant="success"
                  disabled={who === "buyer"}
                  className="btn-buyer"
                  onClick={() => setWho("buyer")}
                >
                  Buyer Registration
                </Button>
                <Button
                  variant="success"
                  disabled={who !== "buyer"}
                  className="btn-farmer"
                  onClick={() => setWho(!who)}
                >
                  Farmer Registration
                </Button>
              </div>

              {who === "buyer" ? (
                <>
                  <div className="buyer-registration">
                    <h3>Sign Up as a Buyer</h3>
                  </div>

                  <Form className="form" onSubmit={handleSubmit}>
                    <Container>
                      <Row>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            className="w-100"
                            name="firstName"
                            id="firstName"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="lastName">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            name="lastName"
                            id="lastName"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="phone">
                            Phone
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            required
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                            name="phone"
                            id="phone"
                          />
                        </Col>

                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="email">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            name="email"
                            id="email"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="password">
                            Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            required
                            value={pwd}
                            onChange={(e) => setpwd(e.target.value)}
                            name="password"
                            id="password"
                          />
                        </Col>

                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="cPassword">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            required
                            value={pwd2}
                            onChange={(e) => setpwd2(e.target.value)}
                            id="cPassword"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="d-flex gap-2 chk-bx">
                          <Form.Check
                            required
                            type="checkbox"
                            id="notifications"
                          />
                          <Form.Label htmlFor="notifications">
                            I want to receive notifications from{" "}
                            <Link to="#" className="signUp-checkbox">
                              BuynBulk
                            </Link>
                          </Form.Label>
                        </div>
                        <div className="d-flex gap-2 chk-bx">
                          <Form.Check required type="checkbox" id="TandC" />
                          <Form.Label htmlFor="TandC">
                            I agree to the{" "}
                            <Link to="#" className="signUp-checkbox">
                              terms and conditions
                            </Link>
                          </Form.Label>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <div className="or my-3">
                            <hr className="hr-width" />
                            OR
                            <hr className="hr-width" />
                          </div>
                        </Col>
                      </Row>
                      <Row
                        className="d-flex justify-content-center 
                                        align-items-center gap-3 
                                        flex-direction-col"
                      >
                        <GoogleOAuthButton />
                      </Row>
                      <Row>
                        <div>
                          <div className="btn-reg-holder my-4">
                            {!loading ? (
                              <>
                                <Button
                                  className="btn-register"
                                  type="submit"
                                  variant="success"
                                >
                                  Register
                                </Button>
                                <div className="animated-square"></div>
                              </>
                            ) : (
                              <Button
                                disabled={loading}
                                variant="success"
                                className="btn-register "
                              >
                                <span>
                                  {" "}
                                  <BeatLoader
                                    color="#f3e41e"
                                    loading={loading}
                                    size={17}
                                  />{" "}
                                </span>
                              </Button>
                            )}
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <p className="a-user mb-5">
                          Already have an account?{" "}
                          <Link to="/BSignIn">Log in</Link>
                        </p>
                      </Row>
                    </Container>
                  </Form>
                </>
              ) : (
                <>
                  <div className="farmer-registration">
                    <h3>Sign Up as a Farmer</h3>
                  </div>
                  <Form className="form" onSubmit={handleSubmit}>
                    <Container>
                      <Row>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            className="w-100"
                            name="firstName"
                            id="firstName"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="lastName">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            name="lastName"
                            id="lastName"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="phone">
                            Phone
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            required
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                            name="phone"
                            id="phone"
                          />
                        </Col>

                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="email">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            name="email"
                            id="email"
                          />
                        </Col>
                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="password">
                            Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            required
                            value={pwd}
                            onChange={(e) => setpwd(e.target.value)}
                            name="password"
                            id="password"
                          />
                        </Col>

                        <Col sm={12} md={6} className="input-div mb-3">
                          <Form.Label className="mb-1" htmlFor="cPassword">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            required
                            value={pwd2}
                            onChange={(e) => setpwd2(e.target.value)}
                            id="cPassword"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="d-flex gap-2">
                          <Form.Check
                            required
                            type="checkbox"
                            id="notifications"
                          />
                          <Form.Label htmlFor="notifications">
                            I want to receive notifications from{" "}
                            <Link to="#" className="signUp-checkbox">
                              BuynBulk
                            </Link>
                          </Form.Label>
                        </div>
                        <div className="d-flex gap-2">
                          <Form.Check required type="checkbox" id="TandC" />
                          <Form.Label htmlFor="TandC">
                            I agree to the{" "}
                            <Link to="#" className="signUp-checkbox">
                              terms and conditions
                            </Link>
                          </Form.Label>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <div className="or my-3">
                            <hr className="hr-width" />
                            OR
                            <hr className="hr-width" />
                          </div>
                        </Col>
                      </Row>
                      <Row
                        className="d-flex justify-content-center 
                                    align-items-center gap-3 
                                    flex-direction-col"
                      >
                        <GoogleOAuthButton />
                      </Row>
                      <Row>
                        <div>
                          <div className="btn-reg-holder my-4">
                            {!loading ? (
                              <>
                                <Button
                                  className="btn-register"
                                  type="submit"
                                  variant="success"
                                >
                                  Register
                                </Button>
                                <div className="animated-square"></div>
                              </>
                            ) : (
                              <Button
                                disabled={loading}
                                variant="success"
                                className="btn-register "
                              >
                                <span>
                                  {" "}
                                  <BeatLoader
                                    color="#f3e41e"
                                    loading={loading}
                                    size={17}
                                  />{" "}
                                </span>
                              </Button>
                            )}
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <p className="a-user mb-5">
                          Already have an account?{" "}
                          <Link to="/FSignIn">Log in</Link>
                        </p>
                      </Row>
                    </Container>
                  </Form>
                </>
              )}
            </Container>
          </Col>
          <Col className="adj">
            <section className="text-center">
              <Container className="">
                <img src={SignInTypeImg} alt="farm" className="w-100" />
              </Container>
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SignUpForm;
