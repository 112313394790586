import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Table, Container, Spinner, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import './UserTable.css'; // Import your CSS file here
import UserModal from './UserModal';

Modal.setAppElement('#root'); // For accessibility reasons

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { token } = useSelector((state) => state.auth.user);

  const config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://buyinbulk-zxvi.onrender.com/api/v1/auth/all', { headers: config });
        setUsers(response.data.usersWithDetails);
        // console.log(response.data.usersWithDetails[0].products)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
  };

 const handleOpenModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  const filteredUsers = users.filter(user => 
    (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) || 
    user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (roleFilter ? user.role === roleFilter : true)
  );

  const offset = currentPage * usersPerPage;
  const currentUsers = filteredUsers.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" style={{ color: '#116530' }} />
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <h1 className="text-center" style={{ color: '#116530' }}>All BuyinBulk Users</h1>
      <Row className="mb-3">
        <Col>
          <h5>Total Users: {filteredUsers.length}</h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <InputGroup>
            <Form.Control 
              type="text" 
              placeholder="Search by name or email" 
              value={searchQuery} 
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Col>
        <Col md={6}>
          <Form.Select value={roleFilter} onChange={handleRoleFilterChange}>
            <option value="">All Roles</option>
            <option value="Farmer">Farmer</option>
            <option value="Admin">Admin</option>
            <option value="Buyer">User</option>
          </Form.Select>
        </Col>
      </Row>
      <Table striped bordered hover responsive>
        <thead style={{ backgroundColor: '#116530', color: 'white' }}>
          <tr>
            <th scope="col" className="text-center">Verified</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Subscription</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td className="text-center">
                {user.verified ? (
                  <FaCheckCircle style={{ color: 'green' }} />
                ) : (
                  <FaTimesCircle style={{ color: 'red' }} />
                )}
              </td>
              <td>{user.firstName} {user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                {user.role === 'Farmer' ? (
                  <>
                    {user.subscriptionPlan} {user.subscriptionStatus ? 'Active' : 'Inactive'}
                  </>
                ) : 'Not Eligible'}
              </td>
              <td>
                <Button 
                  variant="" 
                  style={{backgroundColor: '#116530',color: "white"}}
                  onClick={() => handleOpenModal(user)}
                >
                  View More
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      {/* Modal for viewing user details */}
      <UserModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        user={selectedUser}
      />
      
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-left'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </Container>
  );
};

export default UserTable;
