import { createContext, useState } from "react";

export const ProductContextProvider = ({ children }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [percentVal, setPercentVal] = useState(0);
  const data = {
    currentItem,
    setCurrentItem,
    percentVal,
    setPercentVal,

    name: "use context",
  };
  return (
    <ProductContext.Provider value={data}>{children}</ProductContext.Provider>
  );
};

export const CartItems = ({ children }) => {
  const [cartItem, setCartItem] = useState(null);
  const data = {
    cartItem,
    setCartItem,
  };
  // console.log(cartItem)
  return <CartPortItem.Provider value={data}>{children}</CartPortItem.Provider>;
};

export const AmountToBePay = ({ children }) => {
  const [AmtToPay, setAmtToPay] = useState(null);
  const data = {
    AmtToPay,
    setAmtToPay,
  };
  return <AmountToPay.Provider value={data}>{children}</AmountToPay.Provider>;
};

export const ModifyLogin = ({ children }) => {
  const [loadPage, setLoadPage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const data = {
    loadPage,
    setLoadPage,
    isActive,
    setIsActive,
    isActive2,
    setIsActive2,
  };
  return <LoginMod.Provider value={data}>{children}</LoginMod.Provider>;
};
export const LoginMod = createContext();

// export const UserContext = createContext(null);

export const AmountToPay = createContext();

export const CartPortItem = createContext();

export const ProductContext = createContext();
