import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { MdShoppingCart } from "react-icons/md";
import "../Assets/css/Header.css";
import { useCart } from "react-use-cart";
import Avatar from "../Assets/images/avatar.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/auth/auth.slice";
import axios from "axios";
import GoogleCallBack from "./GoogleCallback";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dropDown, setDropDown] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [googleUser, setGoogleUser] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [cartLength, setCartLength] = useState(0);
  const token = JSON.parse(localStorage.getItem("user"))?.token;

  const userRole = user && user.responseBody.role;
  const isSubscribed = user && user.responseBody.subscribed;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const api_url = "https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/";

  useEffect(() => {
    const getCart = async () => {
      try {
        const response = await axios.get(`${api_url}cart`, config);
        setCartItems(response?.data?.userCart?.items);
        setCartLength(response?.data?.userCart?.items?.length);
      } catch (error) {
        console.error("Error fetching user cart:", error);
      }
    };

    getCart();
  }, []);

  const Brand =
    "https://res.cloudinary.com/okpanz/image/upload/v1703836081/BuynBulk_m5picq.png";

  const HandleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(user));
    window.location.href = "/signInAs";
    setDropDown(false);
  };

  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  const { totalUniqueItems } = useCart();

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <Navbar key="lg" expand="lg" className="mb-3 header-bg">
        <Container className="mb-4 ">
          <Navbar.Toggle
            className="bg-light cursor-pointer"
            aria-controls={`offcanvasNavbar-expand-lg`}
          />
          <Navbar.Brand href="/" className="text-light fs-1 fw-bold">
            <img
              src={Brand}
              alt="buynbulk"
              width="100"
              height="100"
              className="w-100 cursor-pointer"
            />
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            style={{
              backgroundColor: "#116530",
            }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <Link to="/" className="text-light fs-1 fw-bold">
                  BuynBulk
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className="justify-content-center gap-4 flex-grow-1 mx-5 navLinksHome"
                style={{ color: "white" }}
              >
                <Link
                  to="/"
                  className={`navLink text-white font-bold cursor-pointer ${
                    isActive("/") ? "activePage" : ""
                  }`}
                >
                  Home
                </Link>
                <Link
                  to="/shop"
                  className={`navLink text-white font-bold cursor-pointer ${
                    isActive("/shop") ? "activePage" : ""
                  }`}
                >
                  Categories
                </Link>
                <Link
                  to="/about"
                  className={`navLink text-white font-bold cursor-pointer ${
                    isActive("/about") ? "activePage" : ""
                  }`}
                >
                  About Us
                </Link>
                <Link
                  to="/contact"
                  className={`navLink text-white font-bold cursor-pointer ${
                    isActive("/contact") ? "activePage" : ""
                  }`}
                >
                  Contact
                </Link>
                {(!isSubscribed && userRole === "Admin") || !user ? (
                  <Link
                    to="/pricing"
                    className={`navLink text-white font-bold cursor-pointer ${
                      isActive("/pricing") ? "activePage" : ""
                    }`}
                  >
                    Pricing
                  </Link>
                ) : null}
                <NavDropdown
                  className="show-dropdown-offset cursor-pointer"
                  title={
                    <img
                      src={
                        (user ? user?.responseBody?.avatar : null) ||
                        (user ? user?.responseBody?.image : null) ||
                        Avatar
                      }
                      alt={user?.lastName}
                      className="profileBar mx-2"
                    />
                  }
                  id={``}
                >
                  {user ? (
                    <>
                      <NavDropdown.Item href="/sidebar/FProfile">
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/sidebar/Fdashboard">
                        Dashboard
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="" onClick={HandleLogout}>
                        Sign out
                      </NavDropdown.Item>
                    </>
                  ) : (
                    <NavDropdown.Item href="/signInAs">
                      Sign In / Sign Up
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Nav className="nav-right-items">
            <NavDropdown
              className="show-dropdown-nav cursor-pointer"
              title={
                <img
                  style={{ width: "2.5rem" }}
                  src={
                    (user ? user?.responseBody?.avatar : null) ||
                    (user ? user?.responseBody?.image : null) ||
                    Avatar
                  }
                  alt={user?.lastName}
                  className="profileBar mx-2"
                />
              }
              id={``}
            >
              {user && user ? (
                <>
                  <NavDropdown.Item href="/sidebar/FProfile">
                    Profile
                  </NavDropdown.Item>
                  {userRole == "Admin" || userRole == "Farmer" ? (
                    <NavDropdown.Item href="/sidebar/FDashboard">
                      Dashboard
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item href="/sidebar/purchased">
                      Dashboard
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="" onClick={HandleLogout}>
                    Sign out
                  </NavDropdown.Item>
                </>
              ) : (
                <NavDropdown.Item href="/signInAs">
                  Sign In / Sign Up
                </NavDropdown.Item>
              )}
            </NavDropdown>
            <Nav.Link href="/cart" className="rel cursor-pointer">
              <span className="material-symbols-outlined cart-home-header">
                <MdShoppingCart />
              </span>
              <span className="counter">{cartLength}</span>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
