import React, { useEffect, useState, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const token = JSON.parse(localStorage.getItem("user"))?.token;

  const config = useMemo(
    () => ({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const api_url = "https://buyinbulk-zxvi.onrender.com/api/v1/crowdfund/";
  const navigate = useNavigate();

  useEffect(() => {
    const getCart = async () => {
      try {
        const response = await axios.get(`${api_url}cart`, config);
        setCartItems(response.data.userCart.items);
      } catch (error) {
        console.error("Error fetching user cart:", error);
      }
    };

    getCart();
  }, []);
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(price);
  };
  const initiatePayment = async (cartItemId) => {
    try {
      const response = await axios.post(
        `${api_url}generate-flutterwave-link`,
        { cartItemId },
        config
      );
      // console.log("Initiate Payment Response:", response.data.link);
      window.location.href = response.data.link;
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const calculateTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.amountPaid, 0);
  };

  const handlePayment = (item) => {
    initiatePayment(item._id);
  };

  return (
    <>
      <Header />
      <Container className="my-4">
        <div className="mb-4">
          <h2 className="fs-5 mb-3">Cart Summary ({cartItems.length})</h2>
        </div>
        {cartItems.length === 0 ? (
          <p className="text-center text-muted">Your cart is empty😥</p>
        ) : (
          <div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>ID</th>
                  <th>Farm Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item._id}>
                    <td>
                      <img
                        className="img-fluid"
                        src={item.product.images[0]}
                        alt={item.productName}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "80px",
                          objectFit: "cover",
                        }}
                      />
                    </td>
                    <td>{item.productId}</td>
                    <td>{item.product.farmName}</td>
                    <td>{item.contributionPercentage}%</td>
                    <td>{formatPrice(item.amountPaid)}</td>
                    <td>
                      <Button
                        onClick={() => handlePayment(item)}
                        variant="success"
                      >
                        Pay Now
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="text-end mt-4">
              <h5>
                Total: <span className="text-success">&#8358;</span>
                {calculateTotalAmount().toLocaleString()}
              </h5>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Cart;
