import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import { AuthService } from "../../services/auth.service";
import { setMessage } from "../messages/message";

// local storage
const user = JSON.parse(localStorage.getItem("user"));
const profileData = JSON.parse(localStorage.getItem("profile"));

export const registerBuyer = createAsyncThunk(
  "auth/register-buyer",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.registerBuyer(payload);
      // thunkAPI.dispatch()
      return res;
    } catch (err) {
      // const msg = err.response.data;
      const msg = err.response;

      const message =
        err.response && err.response.data && err.response.data.message;
      //  ||
      // err.message ||
      // err.toString();
      thunkAPI.dispatch(setMessage(msg.data));
      return thunkAPI.rejectWithValue(msg.data);
    }
  }
);

export const registerFarmer = createAsyncThunk(
  "auth/register-farmer",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.registerFarmer(payload);
      // thunkAPI.dispatch()
      return res;
    } catch (err) {
      const msg = err.response.data;
      thunkAPI.dispatch(setMessage(msg));
      return thunkAPI.rejectWithValue(msg);

      // const message =
      //   (err.response && err.response.data && err.response.data.message) ||
      //   err.message ||
      //   err.toString();
      // thunkAPI.dispatch(setMessage(message));
      // return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.login(payload);
      thunkAPI.fulfillWithValue(true);
      return res;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(false);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.resetPassword(payload);
      thunkAPI.fulfillWithValue(true);
      return res;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(false);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/update-password",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.updatePassword(payload);
      thunkAPI.fulfillWithValue(true);
      return res;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(false);
    }
  }
);
export const loginWithGoogle = createAsyncThunk(
  "auth/google/callback",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.loginWithGoogle(payload);
      console.log(res)
      thunkAPI.fulfillWithValue(true);
      return res;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(false);
    }
  }
);

export const profile = createAsyncThunk(
  "profile/check",
  async (payload, thunkAPI) => {
    try {
    } catch (err) {}
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verify-email",
  async (payload, thunkAPI) => {
    try {
      const res = await AuthService.verifyEmail(payload);
      return res;
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [registerBuyer.fulfilled]: (state, action) => {
      state.isRegistered = true;
    },
    [registerBuyer.rejected]: (state, action) => {
      state.isRegistered = false;
    },
    [registerFarmer.fulfilled]: (state, action) => {
      state.isRegistered = true;
    },
    [registerFarmer.rejected]: (state, action) => {
      state.isRegistered = false;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.user = user;
      state.isLoggedIn = true;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [login.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
