import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Carousel,
  Form,
} from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MoonLoader } from "react-spinners";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Components/Header";
import {
  addPercent,
  removePercent,
  resetPercent,
} from "../redux/products/CrowdfundSlice";

const SingleItem = () => {
  const navigate = useNavigate();
  const { addItem, items, setItems } = useCart();
  const { percent } = useSelector((state) => state.crowdfund);
  const [newwPrice, setNewwPrice] = useState(0);
  const [prod, setProd] = useState(null);
  const [availablePercent, setAvailablePercent] = useState(0);
  const [crowdfundingStatus, setCrowdfundingStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputPercent, setInputPercent] = useState(percent);
  const [fundedPercent, setFundedPercent] = useState(0);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const clickedPercent = +event.target.value;
    if (
      !isNaN(clickedPercent) &&
      clickedPercent >= 0 &&
      clickedPercent <= availablePercent
    ) {
      setInputPercent(clickedPercent);
      setNewwPrice(Math.round((clickedPercent / 100) * prod.productPrice));
    }
  };

  const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";

  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await axios.get(
          `${API_URL}product/singleProduct/${id}`,
          config
        );
        setProd(res.data.data);
      } catch (error) {
        if (error.response.status === 401) {
          console.log("You need to Login to Proceed ");
          toast.error(
            "You are being redirected to create an account or Sign in"
          );
          window.location.href = "/signInAs";
        }
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    if (prod && prod.productPrice) {
      setNewwPrice(Math.round((percent / 100) * prod.productPrice));
      setInputPercent(percent);
    }
  }, [percent, prod?.productPrice]);

  useEffect(() => {
    const fetchCrowdfundingStatus = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${API_URL}crowdfund/status/${id}`, config);
        setCrowdfundingStatus(res.data);
        const fundedAmount = res.data.activeCampaign.currentAmount || 0;
        const goalAmount = res.data.activeCampaign.goalAmount || 1; // Avoid division by zero
        const percentFunded = (fundedAmount / goalAmount) * 100;
        setFundedPercent(percentFunded);
        setAvailablePercent(100 - percentFunded);
      } catch (error) {
        console.error("Error fetching crowdfunding status:", error);
        toast.error("Error fetching crowdfunding status");
      } finally {
        setLoading(false);
      }
    };

    fetchCrowdfundingStatus();
  }, [id]);

  const handleContribute = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;

      if (!token) {
        console.error("Token not available. Please log in.");
        return;
      }

      const campaignId = crowdfundingStatus
        ? crowdfundingStatus.activeCampaign._id
        : null;

      if (!campaignId) {
        console.error("CampaignId not available in crowdfunding status.");
        return;
      }

      if (!newwPrice || isNaN(newwPrice)) {
        console.error("Invalid or missing contribution amount.");
        return;
      }

      const response = await axios.post(
        `${API_URL}/crowdfund/contribute`,
        {
          campaignId: campaignId,
          amount: Math.round(newwPrice),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 403) {
        console.error(
          "Forbidden: You do not have permission to contribute to this campaign."
        );
        return;
      }

      if (response.data.error) {
        console.error(
          "Failed to contribute to crowdfunding campaign:",
          response.data.error
        );
        toast.error("Failed to contribute to crowdfunding campaign");
        return;
      }

      toast.success("Contribution and cart update successful");
      window.location.href = "/cart";
    } catch (error) {
      if (error.response.status === 403) {
        console.log("Profile incomplete");
        toast.error(
          "Profile incomplete. Redirecting you to complete registration"
        );
        window.location.href = "/sidebar/edit";
      }

      console.log(error.response.status);
      console.error("Error contributing to crowdfunding campaign:", error);
      toast.error("Error contributing to crowdfunding campaign");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPercent = () => {
    if (percent < availablePercent) {
      dispatch(addPercent());
      setInputPercent((prev) => Math.min(prev + 1, availablePercent));
    } else {
      console.log("Cannot add more than available percentage");
      toast.error("Cannot add more than available percentage");
    }
  };

  const handleRemovePercent = () => {
    if (percent > 0) {
      dispatch(removePercent());
      setInputPercent((prev) => Math.max(prev - 1, 0));
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const isFullyFunded = fundedPercent >= 100;

  return (
    <div>
      <Header />
      {prod ? (
        <Container className="my-5">
          <Row>
            <Col md={6}>
              <Carousel>
                {prod.productImages.map((image, index) => (
                  <Carousel.Item key={index}>
                    <Image
                      src={image}
                      alt={`Product image ${index + 1}`}
                      fluid
                      className="d-block w-100"
                      style={{ height: "400px", objectFit: "cover" }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="image-thumbnails mt-3">
                {prod.productImages.map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    fluid
                    className="me-2"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document
                        .querySelectorAll(".carousel-item")
                        [index].scrollIntoView({ behavior: "smooth" })
                    }
                  />
                ))}
              </div>
            </Col>
            <Col md={6}>
              <div className="details">
                <h2 className="fs-1">{prod.productName}</h2>
                <p className="fs-6">{prod.productDescription}</p>
                <h3 className="fs-6">{formatPrice(prod.productPrice)}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center my-3">
                <div className="d-flex align-items-center">
                  <Button
                    variant="success"
                    disabled={isFullyFunded || percent === 1 || loading}
                    onClick={handleRemovePercent}
                  >
                    -
                  </Button>
                  <Form.Control
                    type="number"
                    value={inputPercent}
                    onChange={handleChange}
                    min="0"
                    max={availablePercent}
                    className="mx-2"
                    style={{ width: "100px" }}
                  />
                  <Button
                    variant="success"
                    disabled={
                      isFullyFunded || percent >= availablePercent || loading
                    }
                    onClick={handleAddPercent}
                  >
                    +
                  </Button>
                </div>
                {crowdfundingStatus?.crowdfundingEnabled ? (
                  isFullyFunded ? (
                    <Button
                      variant="outline-success"
                      className="py-2 px-3"
                      disabled
                    >
                      Buy Now!
                    </Button>
                  ) : (
                    <Button
                      variant="outline-success"
                      className="py-2 px-3"
                      onClick={handleContribute}
                      disabled={loading}
                    >
                      {loading ? "Contributing" : "Join Crowdfunding"}
                    </Button>
                  )
                ) : (
                  <Button variant="success" className="py-2 px-3" disabled>
                    Crowdfunding not available
                  </Button>
                )}
              </div>
              <ProgressBar variant="success" now={fundedPercent} />
              <small className="d-flex justify-content-end mt-2">
                {fundedPercent
                  ? `${fundedPercent.toFixed(2)}% funded`
                  : "0% funded"}
              </small>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="singleItem-loader d-flex justify-content-center align-items-center">
          <MoonLoader color="#116530" size="75px" />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SingleItem;
