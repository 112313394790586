import "../Assets/Css/Pages css/FProfile.css"
import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import axios from "axios"
import { useSelector } from 'react-redux'
import { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import EditProfileForm from "../Components/editProfileForm"

const FProfile = () => {
    const { user } = useSelector((state) => state.auth)
    const [getProfile, setGetProfile] = useState()
    const [createProfile, setCreateProfile] = useState()
    const { token } = useSelector((state) => state.auth.user);
    const [website, setWebsite] = useState()
    const [address, setAddress] = useState()
    const [about, setAbout] = useState()
    const [phone, setPhone] = useState()
    const [toggle, setToggle] = useState(false)
    const [role, setRole] = useState()
    const [avatar, setAvatar] = useState()
  
  

    // if(role === "Buyer"){

    // }
    const handleToggle = () => {
        setToggle(!toggle)
    }

    const config = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };


    const API = "https://buyinbulk-zxvi.onrender.com/api/v1/profile"

    useEffect(() => {
        const image= JSON.parse(localStorage.getItem('user')).responseBody.image
        setAvatar(image)
        axios.get(API + "/check", { headers: config })
            .then(res => {
                setGetProfile(res.data)
            }).catch(err => {
                console.log(err)
            })

    }, [])

   
    return (
        <Container className="w-75 FProfile">
            <img src={user.responseBody.avatar || avatar} alt="" className="w-25" />
            <Form>
                <Row>
                    <Col xs="12" md="6" className="gap-5">
                        <Form.Control className="input" name="lastName" disabled value={user.responseBody.firstName} />
                        <Form.Control className="input" name="firstName" disabled value={user.responseBody.lastName} />
                        <Form.Control className="input" name="email" disabled value={user.responseBody.email} />
                        <Form.Control className="input" name="role" disabled value={user.responseBody.role} />
                    </Col>


                    <Col xs="12" md="6">
                        <Form.Control className="input" name="website" placeholder="Handle" value={getProfile?.website} />
                        <Form.Control className="input" name="address" placeholder="Address" value={getProfile?.address} />
                        <Form.Control className="input" name="about" placeholder="About" value={getProfile?.about}/>
                        <Form.Control className="input" name="phone" placeholder="Phone" value={getProfile?.phone}/>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default FProfile