/* note that the layout for this file is used both
for the buyer and the farmer sign in */
import { toast } from "react-toastify";
import "../Assets/css/pages Css/SignIn.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineUser } from "react-icons/ai";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/auth/auth.slice";
import GoogleOAuthButton from "../Components/GoogleOAuthButton";

const FSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [loading, setLoading] = useState();

  const farmerData = { email, password: pwd };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await dispatch(login(farmerData));
      if (!res.error) {
        setLoading(false);
        toast.success("Login Successful!");
        setTimeout(() => {
          window.location.href="/sidebar/FProfile";
        }, 1500);
      } else {
        toast.error("Invalid email or password");
        setLoading(false);
      }
    } catch (error) {
      if (error?.res.error) {
        // setError("An error has occured");
      }
    }
  };

  return (
    <div className="signIn-page">
      <Row>
        <Col md="6" className="leftSide">
          <Container>
            <div className="left-signIn-page">
              <h3 className="brand mt-2">
                <Link to="/">BuynBulk </Link>
              </h3>
            </div>
            <div className="left-text">
              <h2>Welcome Back</h2>
              <p>
                To keep connected with us please login with your personal
                details
              </p>
            </div>
          </Container>
        </Col>
        <Col sm="12" md="6" className="right-side">
          <Container className=" w-sm-100 w-md-75">
            <div className="right-signIn-page">
              <div className="panel-body">
                <Form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="row">
                      <div className="center-block">
                        <AiOutlineUser className="profile-img" />
                      </div>
                    </div>
                    <h2 className="mb-3 cl-h2">FARMER LOGIN</h2>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="glyphicon glyphicon-user"></i>
                            </span>
                            <Form.Control
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                              name="email"
                              required
                              type="email"
                              autoFocus
                            />
                          </div>
                        </Form.Group>
                        <Form.Group>
                          <div className="input-group my-3">
                            <span className="input-group-addon">
                              <i className="glyphicon glyphicon-lock"></i>
                            </span>
                            <Form.Control
                              className="form-control"
                              value={pwd}
                              onChange={(e) => setPwd(e.target.value)}
                              placeholder="Password"
                              name="password"
                              required
                              type="password"
                              minLength={6}
                            />
                          </div>
                          <span className="forgot text-left">
                            <Link to="#" className="text-success">
                              Having trouble signing in?
                            </Link>
                          </span>
                        </Form.Group>
                        <Form.Group>
                          {!loading ? (
                            <Button
                              type="submit"
                              className="my-4 btn btn-lg btn-success btn-block fw-normal text-light w-100"
                            >
                              Sign In
                            </Button>
                          ) : (
                            <Button
                              disabled
                              className="my-4 btn btn-lg btn-success btn-block fw-normal text-light w-100"
                            >
                              Signing in...
                            </Button>
                          )}
                        </Form.Group>
                        <div className="or-sign-with">
                          <hr className="hr-w" />
                          Or sign in with
                          <hr className="hr-w" />
                        </div>
                        <Form.Group>
                          <div className="hld-btns-with my-2">
                            {/* <div>
                              <Button
                                variant="outline-success"
                                className="btn-sign-w"
                              >
                                <FaFacebookF />
                                Facebook
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="outline-success"
                                className="btn-sign-w"
                              >
                                <FcGoogle />
                                Google
                              </Button>
                            </div>*/}
                          <GoogleOAuthButton />
                          </div> 
                        </Form.Group>
                        <small className="not-user mt-3">
                          Don't have an account?{" "}
                          <Link to="/signUpForm"> Sign Up!</Link>
                        </small>
                      </Col>
                    </Row>
                  </fieldset>
                </Form>
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default FSignIn;
