import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "../Assets/css/pages Css/ContactUsPage.css"
import ContactIllustration from "../Assets/images/4169536.jpg"
import Footer from "../Components/footer";
import Header from "../Components/Header";
import { HiLocationMarker } from "react-icons/hi"
import { MdCall } from "react-icons/md"
import { AiFillMail } from "react-icons/ai"
const ContactUsPage = () => {
    return (
        <>
            <Header activePage={"contact"} />
            <section className=" contact my-5">
                <Container>
                    <Row className="contact-row">
                        <Col xs="12" lg="6">
                            <div className="contact-form">
                                <h2 className="text-success">Get in Touch</h2>
                                <div className="form-group mb-3">
                                    <label className="control-label col-sm-2" htmlFor="name">Name:</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="name" placeholder="Enter Name" name="name" />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label col-sm-2" htmlFor="email">Email:</label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="control-label col-sm-2" htmlFor="comment">Comment:</label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control" rows="5" id="comment"></textarea>
                                    </div>
                                </div>
                               <div className="form-group mb-3">
                                    {/* <label className="control-label col-sm-2" htmlFor="email">Email:</label> */}
                                    <div className="col-sm-10">
                                        <button className="form-control btn btn-success w-100" id="email" placeholder="Enter email" name="email" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs="12" lg="6">
                            <div className="contact-illustration">
                                <img src={ContactIllustration} alt="contact-illustration" className="w-50" />
                            </div>
                            <div className="contact-left-side">
                                {/* <h2>CONTACT-US</h2>
                                <p>You can contact us with the following:</p> */}
                                <span>
                                    <HiLocationMarker className="contact-icon" />
                                    Old Airport junction market, 2nd floor TEAN
                                    Business complex, opposite former NITEL office
                                    ,JOS
                                </span>
                                <span>
                                    <MdCall className="contact-icon" />
                                    +2348164482158
                                </span>
                                <span href="mailto:buynbulk22@gmail.com">
                                    <AiFillMail className="contact-icon" />
                                    buynbulk22@gmail.com
                                </span>
                            </div>
                        </Col>







                        {/* <Col md="6" xs="12" className="textarea-section">
                            <div className="textarea-holder">
                                <textarea rows="5"
                                    cols="60"
                                    className="textarea p-3 fs-5 w-100"
                                    placeholder="Type message..">
                                </textarea>
                                <Button
                                    variant="success"
                                    className="btn-send px-5 py-3">
                                    Send
                                </Button>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default ContactUsPage;