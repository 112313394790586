import "../Assets/Css/TopNav.css";
import { BiArrowBack } from "react-icons/bi";

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TopNav = () => {

    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user?.responseBody);
    return (
        <div className="FTop_Nav mb-3">
            <span
                onClick={() => navigate(-1)}
                className="material-symbols-outlined back_arrow"
            >
                arrow_back
            </span>
            <h2 className="greeting_text">
                Hello <span className="green_text">{user?.lastName}</span>
            </h2>
            <span>
                <img className="topNav_avatar" src={user?.avatar || user?.image} alt={`${user?.firstName}  ${user?.lastName}`} />
            </span>
        </div>
    );
};

export default TopNav;
