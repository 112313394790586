import axios from "axios";

const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";

class AuthClass {
  // register buyer
  async registerBuyer(payload) {
    const res = await axios.post(API_URL + "auth/register-buyer", {
      ...payload,
    });
    return res;
  }

  // register Farmer
  async registerFarmer(payload) {
    const res = await axios.post(API_URL + "auth/register-farmer", {
      ...payload,
    });
    return res;
  }

  // verify email
  async verifyEmail(payload) {
    const res = await axios.post(API_URL + "auth/verify-email", {
      ...payload,
    });

    if (res.token) {
      return localStorage.setItem("user", JSON.stringify(res.token));
    }
  }

  // login
  async login(payload) {
    const res = await axios.post(API_URL + "auth/login", {
      ...payload,
    });
    // console.log(res.data.token);
    if (res.data.token) {
      localStorage.setItem("user", JSON.stringify({ ...res.data, avatar: "" }));
    }
    return res.data;
  }
  // reset password

  async resetPassword(payload) {
    const res = await axios.post(API_URL + "auth/reset-password", {
      ...payload,
    });
    return res;
  }

  // Update-password

  async updatePassword(payload) {
    const res = axios.post(API_URL + "auth/update-password", {
      ...payload,
    });
    return res;
  }
  // Google Login
  async loginWithGoogle(payload) {
    const res = await axios.post(API_URL + "auth/google/callback", {
      ...payload,
    });

    if (res.data.token) {
      localStorage.setItem("user", JSON.stringify({ ...res.data, avatar: "" }));
    }
    return res.data;
  }

  async resendOtp(payload) {
    const res = await axios.post(API_URL + "auth/resend-otp", {
      ...payload,
    });
    return res;
  }
  async profile(payload) {
    const res = await axios.fetch(API_URL + "profile/", {
      ...payload,
    });
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
    }
  }

  //Pricing
  async createCrowdfund(payload) {
    const res = await axios.post(API_URL + "getall/all", {
      ...payload,
    });
    if (res.data) {
      localStorage.setItem("crowdfund", JSON.stringify(res.data));
    }
  }

  // logout
  async logout() {
    localStorage.removeItem("user");
  }
}

export const AuthService = new AuthClass();
