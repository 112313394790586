import "../Assets/Css/Pages css/FCategory.css";

import { useSelector, useDispatch } from "react-redux";
// import { amount } from '../../redux/products/CrowdfundSlice'
import BeatLoader from "react-spinners/BeatLoader";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MdHighlightOff } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import Form from "react-bootstrap/esm/Form";

import Button from "react-bootstrap/Button";

const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";

const FCategory = () => {
  const { token } = useSelector((state) => state.auth.user);
  const categoryName = useSelector((state) => state.category.categoryName);
  const message = useSelector((state) => state.message);

  // const {amount} = useSelector((state) => state.crowdfund)

  let [color, setColor] = useState("#f3e41e");
  let [loading, setLoading] = useState(false);
  const [categoriesName, setCategoriesName] = useState("");
  const [categoriesStatus, setCategoriesStatus] = useState();
  const [categoriesDescription, setCategoriesDescription] = useState("");
  const [categoriesImage, setCategoriesImage] = useState();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const { handleSubmit, formState } = useForm();

  const config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const newCategory = async () => {
    try {
      // dispatch(addCategory(categoriesName))
      const data = new FormData();

      data.append("categoriesName", categoriesName);
      data.append("categoriesStatus", "active");
      data.append("categoriesDescription", categoriesDescription);
      data.append("categoriesImage", categoriesImage);

      // console.log(data);

      const Category = await axios.post(
        "https://buy-in-bulk.vercel.app/api/v1/category/create",
        data,
        { headers: config }
      );
      setLoading(false);
      setSuccess(true);
      toast.success("Created");
      setTimeout(() => {
        setSuccess(false);
      }, 15000);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Failed to Create Check your inputs");
    }
  };

  return (
    <div>
      <div className="createCategory">
        {success && (
          <div
            className="d-flex "
            style={{
              width: "fit-content",
              borderRadius: "10px",
              backgroundColor: "#116530bc",
              fontWeight: "600",
              transition: "ease-in-out",
              margin: "0 auto",
              color: "white",
              position: "fixed",
              top: "0",
              zIndex: "1",
              left: "40%",
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto 0",
                padding: "10px",
              }}
            >
              Category created successfully! <br />
              <div onClick={() => setSuccess(false)}>
                <MdHighlightOff
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                />
              </div>
            </p>
          </div>
        )}
        <h2 className="createCat_Header text-light w-75 mb-2">
          Create Category
        </h2>

        <Form className="createCat_form w-75 gap-3">
          <Form.Group className="form-floating">
            <Form.Control
              onChange={(e) => setCategoriesName(e.target.value)}
              value={categoriesName}
              type="text"
              id="jj"
              placeholder="heryy"
              className="form-control"
            />
            <Form.Label htmlFor="jj">Categories Name</Form.Label>
          </Form.Group>

          <Form.Group className="form-floating">
            <Form.Control
              onChange={(e) => setCategoriesDescription(e.target.value)}
              value={categoriesDescription}
              type="text"
              id="jj"
              placeholder="heryy"
              className="form-control"
            />
            <Form.Label htmlFor="jj">Categories Description</Form.Label>
          </Form.Group>

          <Form.Select
            onChange={(e) => setCategoriesStatus(e.target.value)}
            value={categoriesStatus}
            aria-label="select"
          >
            <option>--Category Status--</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </Form.Select>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="formFileMultiple" className=" form-label mb-0">
              Upload Product Image
            </Form.Label>
            <Form.Control
              onChange={(e) => setCategoriesImage(e.target.files[0])}
              className="form-control"
              type="file"
              id="formFileMultiple"
            />
          </Form.Group>
          {!loading ? (
            <Button
              className="btn btn-success my-3 w-auto mx-auto"
              onClick={() => {
                setLoading(!loading);
                newCategory();
              }}
            >
              Update Category
            </Button>
          ) : (
            <Button
              disabled={loading}
              className="btn btn-success my-3 w-auto  mx-auto"
            >
              <span>
                {" "}
                <BeatLoader color={color} loading={loading} size={15} />{" "}
              </span>
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default FCategory;
