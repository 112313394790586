import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import axios from 'axios';
import { VscDebugContinue } from "react-icons/vsc";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';
const ProductPurchase = () => {
    const [tx_ref, setTransaction_id] = useState()
    const [id, setId] = useState()
    const [loading, setLoading] = useState(false)


    const navigate=useNavigate()
    useEffect(() => {
        // Access the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const subscriptionPlan = urlParams.get('tx_ref');
        const userId = urlParams.get('transaction_id');
        setTransaction_id(userId)
        setId(subscriptionPlan)
        // console.log('Subscription Plan:', subscriptionPlan);
        // console.log('User ID:', userId);
    }, []);
    const confirmTransaction = async () => {
            setLoading(true)
        try {
            const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/"
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user ? user.token : null;
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            const response =await axios.post(`${API_URL}crowdfund/validate-payment/${id}`,
                {txRef:tx_ref}, config
            )
            toast.success("Payment Confirmed, Thank you for choosing us")
            toast.success("You are being redirected")
            setLoading(false)
            setTimeout(() => {
                window.location.href='sidebar/purchased'
                // navigate('/sidebar/purchased ')
            }, 1000);
            // console.log(response)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    return (
        <div>
            <Header />
            <div className="d-flex justify-content-center align-items-center flex-column">
                <img src="https://i.pinimg.com/564x/d4/07/51/d407512e9f9f2b1771339f0405a68e7f.jpg" alt="" className='w-25' />
                <small className='fst-italic my-2 text-success'>Do not Leave Page, Complete process to confirm your payment</small>
                <button className={`btn btn-outline-success d-flex align-items-center `} disabled={loading} onClick={confirmTransaction}>
                    {loading ? (
             <>
          Loading <ClipLoader  className='mx-1'
          size={20}
          color='green'
          /> 
            </>
            ) : (
                <>
                Continue <VscDebugContinue className='mx-1 ' />
                </>
            )}
                </button>
            </div>
        </div>
    )
}

export default ProductPurchase
