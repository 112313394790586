import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import "../Assets/css/pages Css/Mail.css";
import { VscRefresh } from "react-icons/vsc";
import Header from "../Components/Header";
import MailIllustration from "../Assets/images/mymail.png";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const Mail = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState();
  const [countdown, setCountdown] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true);

  const API_URL = "https://buyinbulk-zxvi.onrender.com/api/v1/";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const password = queryParams.get("password");

  const Notify = () => {
    toast.success("You've been logged in Automatically");
  };

  const verifyAccount = async () => {
    try {
      const otpCode = otp.join(""); // Join the OTP array to form the complete OTP code
      const verification = await axios.post(API_URL + "auth/verify-email", {
        email,
        password,
        otp: otpCode,
      });
      toast.success("Account Verified");
      setTimeout(Notify, 3000);
      window.location.href = "/signInAs";
    } catch (err) {
      console.log(err);
      setError(err.response.data?.message);
      toast.error(error);
    }
  };

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value)) {
      // Only accept digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Move to the next input box
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const resendOtp = async () => {
    try {
      await axios.post(API_URL + "auth/resend-otp", { email });
      toast.success("OTP sent again");
      setCountdown(30); 
      setResendDisabled(true);
    } catch (err) {
      console.log(err);
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    setCountdown(30);
    setResendDisabled(true);
  }, []);

  return (
    <>
      <Header />
      <Container className="w-100 mt-3">
        <Row>
          <Col xs="12" md="6" className="mb-3 mail-image">
            <img
              src={MailIllustration}
              alt="mail-illustration"
              className="w-100"
            />
          </Col>
          <Col className="my-auto mx-auto otp-container">
            <h2>Email Verification</h2>
            <p>
              An OTP has been sent to{" "}
              <span className="mail-email"> {email} </span>
              <br />
              Enter OTP to confirm email
            </p>
            <Form>
              <Form.Group className="otp-inputs">
                {otp.map((data, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    name="otp"
                    maxLength="1"
                    className="otp-input"
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    placeholder="-"
                  />
                ))}
              </Form.Group>
              <Button
                variant="success"
                className="mb-2 ver"
                onClick={verifyAccount}
              >
                Confirm
              </Button>
            </Form>
            <small>Didn't get a confirmation email?</small>
            <br />
            <small>
              Check your spam folder or{" "}
              <Link
                to="#"
                className="send-again"
                onClick={resendOtp}
                disabled={resendDisabled}
              >
                {" "}
                <VscRefresh />
                Send again {resendDisabled ? `in ${countdown}s` : ""}
              </Link>
            </small>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Mail;
