import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { Link, useNavigate } from "react-router-dom"
import "../Assets/css/footer.css"
import { FaFacebookF } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { useSelector } from "react-redux"

const Footer = () => {
    const { user } = useSelector((state) => state.auth)

    const navigate = useNavigate()
    return (
        <>
            <footer className="footer pb-4">
                <Container className="index">
                    <Row className="pt-4 pb-2 mb-5"
                        style={{
                            borderBottom: "2px solid #aaa"
                        }}
                    >
                        <Col className="textVC brand" sm="6"> <Link to="/">BuynBulk </Link></Col>
                        {!user &&
                            <>
                                <Col className="textVC d-none d-md-block" sm="3"> Ready to get started? </Col>
                                <Col className="textVC d-none d-md-block" sm="3">
                                    <Button variant="success" onClick={() => navigate("/signInAs")} className="btn-get-started" size="lg">
                                        Get Started
                                    </Button>
                                </Col>
                            </>
                        }
                    </Row>

                    <Row className="mb-5">
                        <Col xs="6" className="mb-4" sm="3">
                            <p className="newsletter mb-4" >Subscribe to our newsletter</p>
                            <div className="newsletter-span mb-5">
                                <input placeholder="Email address" />
                                <Button variant="success" className="btn-newsletter">
                                    <span className="material-symbols-outlined">
                                        keyboard_arrow_right
                                    </span>
                                </Button>
                            </div>
                        </Col>


                        <Col xs="6" className="mb-4" sm="3">
                            <ul>
                                <li><Link to="#" className="fs-4">Services</Link></li>
                                <li className="weight-low"><Link to="#">Email Marketing</Link></li>
                                <li className="weight-low"><Link to="#">Campaigns</Link></li>
                                <li className="weight-low"><Link to="#">Branding</Link></li>
                                <li className="weight-low"><Link to="#">Offline</Link></li>
                            </ul>
                        </Col>
                        <Col xs="6" className="mb-4" sm="3">
                            <ul>
                                <li><Link to="#" className="fs-4">About</Link></li>
                                <li className="weight-low"><Link to="#">Our Story</Link></li>
                                <li className="weight-low"><Link to="#">Benefits</Link></li>
                                <li className="weight-low"><Link to="#">Team</Link></li>
                                <li className="weight-low"><Link to="#">Careers</Link></li>
                            </ul>
                        </Col>
                        <Col xs="6" className="mb-4" sm="3">
                            <ul>
                                <li><Link to="#" className="fs-4">Help</Link></li>
                                <li className="weight-low"><Link to="#">FAQs</Link></li>
                                <li className="weight-low"><Link to="#">Contact Us</Link></li>
                            </ul>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="6" sm="2" className="mb-3">
                            <small>Terms & Conditions</small>
                        </Col>
                        <Col xs="6" sm="2" className="mb-3">
                            <small>Privacy Policy</small>
                        </Col>
                        <Col ></Col>

                        <Col xs="12" sm="3" className="social-icons">
                            <Link to="#">
                                <FaFacebookF />
                            </Link>
                            <Link to="#">
                                <FaTwitter />
                            </Link>
                            <Link to="">
                                <FaInstagram />
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </footer>
        </>
    );
}

export default Footer;