import "../Assets/css/pages Css/AboutUsPage.css"
import Header from "../Components/Header";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container";
import AboutImg1 from "../Assets/images/Rectangle 16.png"
import AboutImg2 from "../Assets/images/Rectangle 17.png"
import AboutImg3 from "../Assets/images/Rectangle 18.png"
import Footer from "../Components/footer";

const AboutUs = () => {
    return (
        <>
            <div className="aboutUs-top-part">
                <Header activePage={"about"} />
                <Container>
                    <section className="about-section-a py-5">
                        <h2>ABOUT US</h2>
                        <p>
                            The BuynBulk platform is a way to connect the farmers and producers straight to the
                            final consumers through crowdfunding. By doing this, we aim to reduce the supply chain thereby
                            making prices of goods affordable for the consumers and allowing the farmers to sell their
                            products at profitable prices. We provide a platform where people from different places
                            can crowfund and buy products from any part of the country.
                        </p>
                    </section>
                </Container>
            </div>


            <section className="my-4 py-3">
                <Container>
                    <Row>
                        <Col>
                            <section className="about-section-b py-5">
                                <h2>OUR MISSION</h2>
                                <p>
                                    The concept behind the BuynBulk came from the passionate desire to solve the problems of
                                    the food insecurity, hunger and poverty in Nigeria. We provide a marketplace for the farmers
                                    to sell their large produce at a profitable price while helping consumers get these commodities
                                    at the most affordable prices.
                                </p>
                                <br />
                                <p><strong>We set out to:</strong></p>
                                <ul>
                                    <li>Create a balance in the prices of food items</li>
                                    <li>Solve the inefficiencies in the food supply chain </li>
                                    <li>incrrease standard of living  for farmers and food producers</li>
                                </ul>
                            </section>
                        </Col>
                        <Col className="about-img-section">
                            <Row>
                                <Col>
                                    <img src={AboutImg1} className="w-100" alt="a man" />
                                </Col>
                                <Col>
                                    <img src={AboutImg2} className="w-100" alt="carrots" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <img src={AboutImg3} className="w-100 mt-3" alt="farming" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default AboutUs;