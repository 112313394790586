import React from 'react'
import DisplayProduct from './FProducts'

const Dashboard = () => {
  return (
    <div style={{height:"100vh"}}>
    <DisplayProduct />
    </div>
    
  )
}

export default Dashboard