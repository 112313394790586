import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithGoogle } from '../redux/auth/auth.slice';

const GoogleOAuthButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  const handleLogin = async (response) => {
    try {
      setLoading(true); // Set loading to true when login starts
      const { credential } = response;
      // console.log(response)
      const decodedToken = await decodeJwt(credential);
      if (decodedToken) {
        const { sub, email, given_name, family_name, picture } = decodedToken;
        const res = await dispatch(loginWithGoogle({
          googleId: sub,
          email,
          given_name,
          family_name,
          picture,
        }));
        if (!res.error) {
          console.log('Token saved successfully');
          setTimeout(() => {
            setLoading(false); // Set loading to false when login succeeds
            window.location.href='/sidebar/FProfile'
          }, 3000);
        } else {
          console.error('Failed to log in with Google.');
          setLoading(false); // Set loading to false when login fails
        }
      } else {
        console.error('Failed to decode JWT.');
        setLoading(false); // Set loading to false when decoding fails
      }
    } catch (error) {
      console.error('Error handling Google login:', error);
      setLoading(false); // Set loading to false on error
    }
  };

  const decodeJwt = async (token) => {
    try {
      const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? ( // Conditional rendering based on loading state
        <div>Loading...</div>
      ) : (
        <GoogleLogin
          clientId="295048256848-b6nppmgqqqavhes213pv48iprvc1avra.apps.googleusercontent.com"
          onSuccess={handleLogin}
          onFailure={(error) => console.error('Google login failed:', error)}
        />
      )}
    </div>
  );
};

export default GoogleOAuthButton;
