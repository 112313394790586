import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Component }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      navigate("/signInAs", { state: { from: location }, replace: true });
    }
  }, [user, navigate, location]);

  return user ? <Component /> : null;
};

export default PrivateRoute;
