import React from 'react'
import Pur from '../Pages/purchased'
const Purchased = () => {
  return (
    <div>
       <div style={{height:"100vh"}}>
    <Pur />
    </div>
    </div>
  )
}

export default Purchased
