import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  product: [],
  percent: 1,
  amount: null,
  total: 0,
};

export const productFetch = createAsyncThunk(
  "crowdfund/productFetch",
  async (_id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        "https://buyinbulk-zxvi.onrender.com/api/v1/getall/all"
      );
      // console.log(res?.data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error.res?.data);
    }
  }
);

const crowdfundSlice = createSlice({
  name: "crowdfund",
  initialState,
  reducers: {
    addPercent: (state) => {
      if (state.percent < 100) {
        state.percent += 1;
      } else {
        console.log("You've reached your limit");
      }
    },
    removePercent: (state) => {
      if (state.percent > 1) {
        state.percent -= 1;
      } else {
        console.log("Percentage cannot be less than 1");
      }
    },
    resetPercent: (state) => {
      state.percent = 1;
    },
  },
  extraReducers: {
    [productFetch.pending]: (state) => {
      state.amount = "pending";
    },
    [productFetch.fulfilled]: (state, action) => {
      state.amount = "success";
      state.product = action.payload;
    },
    [productFetch.rejected]: (state) => {
      state.amount = "rejected";
    },
  },
});

export const { addPercent, removePercent, resetPercent } =
  crowdfundSlice.actions;
export default crowdfundSlice.reducer;
