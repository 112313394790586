import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const EditProfileForm = () => {
    const { user } = useSelector((state) => state.auth);
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [about, setAbout] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            await axios.patch(
                'https://buyinbulk-zxvi.onrender.com/api/v1/profile/edit',
                { website, address, about, phone },
                config
            );
            setLoading(false);
            toast.success("Profile updated successfully");
        } catch (error) {
            setLoading(false);
            if (error.response) {
                toast.error(error.response.data?.address);
                toast.error(error.response.data?.phone);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log(error.message);
            }
        }
    };

    return (
        <Container className="w-100 py-4">
            <h3 className="text-center mb-4">Profile</h3>
            <div className="text-center mb-4">
                <img src={user.responseBody.avatar || user.responseBody?.image} alt="User Avatar" className="rounded-circle" width={100} height={100} />
            </div>
            <Form onSubmit={handleUpdate}>
                <Row className="mb-3">
                    <Col xs={12} md={6} className="mb-3 mb-md-0">
                        <Form.Control className="mb-3" name="firstName" disabled value={user.responseBody.firstName} />
                        <Form.Control className="mb-3" name="lastName" disabled value={user.responseBody.lastName} />
                        <Form.Control className="mb-3" name="email" disabled value={user.responseBody.email} />
                        <Form.Control className="mb-3" name="role" disabled value={user.responseBody.role} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control className="mb-3" name="website" placeholder="Website" value={website} onChange={(e) => setWebsite(e.target.value)} />
                        <Form.Control className="mb-3" name="address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        <Form.Control className="mb-3" name="about" placeholder="About" value={about} onChange={(e) => setAbout(e.target.value)} />
                        <Form.Control className="mb-3" name="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Col>
                </Row>
                <div className="text-center">
                    <Button
                        type="submit"
                        variant="success"
                        disabled={loading}
                        className="w-100"
                    >
                        {loading ? "Loading..." : "Edit Profile"}
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default EditProfileForm;
