import "../Assets/css/pages Css/SignInTypePage.css";
import SignInTypeImg from "../Assets/images/Group 242.png";
import SignInTypeImgFloatMid from "../Assets/images/Group 245.png";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

//note that the stylesheet and format of this file is used for the other login
//and sin up pages with the left of white, right of green and the curly header
const SignInTypePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="signInTypePage signInTypePage-ext">
        <div className="green-bg"></div>
        <img
          src={SignInTypeImgFloatMid}
          className="SignInTypeImgFloatMid"
          alt="floating-diag"
        />
        <Header />
        <Row className="row-signInTypePage">
          <Col xs="12" lg="6" className="type-section-a-col">
            <Container>
              <section className="type-section-a text-center my-5 pb-3">
                {/* icon to navigate -1 */}
                {/* <MdArrowBackIosNew className="go-back-arrow-on-signIn"/> */}
                <h2>SIGN-IN</h2>
                <Button
                  size="lg"
                  onClick={() => navigate("/BSignIn")}
                  variant="success"
                  className="btn-signInType py-3 w-50 mb-2"
                >
                  Buyer
                </Button>
                <br />
                <Button
                  size="lg"
                  onClick={() => navigate("/FSignIn")}
                  variant="success"
                  className="btn-signInType py-3 w-50 mb-2"
                >
                  Farmer
                </Button>
              </section>
            </Container>
          </Col>
          <Col className="adj">
            <section className=" text-center">
              <Container className="">
                <img src={SignInTypeImg} alt="farm" className="w-55 " />
              </Container>
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SignInTypePage;
