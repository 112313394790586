import "../Assets/Css/Pages css/FProfile.css";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CreateProfileForm from "../Components/createProfileForm";
import EditProfileForm from "../Components/editProfileForm";
import Profile from "../Components/Profile";

const EditProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [getProfile, setGetProfile] = useState();
  const [createProfile, setCreateProfile] = useState();
  const { token } = useSelector((state) => state.auth.user);
  const [website, setWebsite] = useState();
  const [address, setAddress] = useState();
  const [about, setAbout] = useState();
  const [phone, setPhone] = useState();
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const handleToggle = () => {
    setToggle(true);
    setToggle2(false);
  };
  const handleToggle2 = () => {
    setToggle2(true);
    setToggle(false);
  };

  const config = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  // console.log(token);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newProfile = await axios.post(
        API + "/create",
        {
          website,
          address,
          about,
          phone,
        },
        {
          headers: config,
        }
      );

      // console.log(newProfile.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.message);
      }
    }
  };

  const API = "https://buyinbulk-zxvi.onrender.com/api/v1/profile";

  useEffect(() => {
    axios
      .get(API + "/check", { headers: config })
      .then((res) => {
        setGetProfile(res.data);
        // console.log(getProfile);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container className="w-75 FProfile " style={{ position: "relative" }}>
      <Button
        className={`${
          !toggle ? "displayNone" : ""
        } d-flex justify-center bg-success border-0`}
        onClick={handleToggle2}
      >
        {" "}
        Return{" "}
      </Button>
      <div
        style={{
          marginLeft: "auto",
          position: "absolute",
          right: "0",
          fontSize: ".8rem",
        }}
      >
        <p className={`${toggle && "displayNone"}`}>
          {" "}
          Don't have a profile yet?{" "}
          <span style={{ cursor: "pointer" }} onClick={handleToggle}>
            Create Profile
          </span>{" "}
        </p>
      </div>

      {toggle ? <EditProfileForm /> : <CreateProfileForm />}
    </Container>
  );
};

export default EditProfile;
