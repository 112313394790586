import "../Assets/Css/Pages css/SideBar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes, FaUserAltSlash } from "react-icons/fa";
import {
  MdAccountBalanceWallet,
  MdLogout,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaUserAlt, FaProductHunt, FaUserEdit } from "react-icons/fa";
import { HiMiniBanknotes } from "react-icons/hi2";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/auth/auth.slice";

const FSideBar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState();
  const toggle = () => setIsOpen(!isOpen);

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    setRole(user?.responseBody.role);
  }, [user]);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(user));
    navigate("/");
  };

  const menuItems = [
    { path: "Fdashboard", name: "Dashboard", icon: <MdSpaceDashboard /> },
    { path: "purchased", name: "Purchased", icon: <BiSolidPurchaseTag /> },
    { path: "Edit", name: "Edit", icon: <FaUserEdit /> },
    { path: "FProfile", name: "Profile", icon: <FaUserAlt /> },
    { path: "FProduct", name: "Products", icon: <FaProductHunt /> },
    {
      path: "acctupload",
      name: "Upload Account",
      icon: <MdAccountBalanceWallet />,
    },
  ];

  const buyerMenuItems = [
    { path: "purchased", name: "Purchased", icon: <BiSolidPurchaseTag /> },
    { path: "Edit", name: "Edit", icon: <FaUserEdit /> },
    { path: "FProfile", name: "Profile", icon: <FaUserAlt /> },
  ];

  const adminMenuItems = [
    ...menuItems,
    { path: "funds", name: "Funds Action", icon: <HiMiniBanknotes /> },
    { path: "users", name: "Users", icon: <FaUserAltSlash /> },
  ];

  let currentMenuItems = [];
  if (role === "Admin") {
    currentMenuItems = adminMenuItems;
  } else if (role === "Buyer") {
    currentMenuItems = buyerMenuItems;
  } else {
    currentMenuItems = menuItems;
  }

  return (
    <div className="cont_sideBar">
      <div
        className={`sidebar ${isOpen ? "" : ""} ${
          isOpen ? "" : "shrink"
        }`}
      >
        <div className="top_section">
          <div className="bars" onClick={toggle}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          <div
            onClick={() => navigate("/")}
            className={`logo ${!isOpen ? "hidden" : ""}`}
          >
            <img
              src="https://res.cloudinary.com/okpanz/image/upload/v1703836081/BuynBulk_m5picq.png"
              width={70}
              alt="BuynBulk Logo"
            />
          </div>
        </div>
        <div className="menu_items_container">
          <hr />
          {currentMenuItems.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              onClick={() => {
                if (isOpen) setIsOpen(false);
              }}
            >
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
        </div>
        <div className="logout_section">
          <Link to="" onClick={handleLogout} className="link">
            <div className="icon">{<MdLogout />}</div>
            <div className="link_text">Logout</div>
          </Link>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default FSideBar;
