import Header from "../Components/Header";
import "../Assets/css/pages Css/HomePage.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import TopSecImg from "../Assets/images/Group 6.png"
import AboutUsImg from "../Assets/images/Group 169.png"
import LetsWorkImg from "../Assets/images/Group 175.png"
import AYam from "../Assets/images/unsplash_xTp3YB3_cCM.png"
import Seeds from "../Assets/images/unsplash_v_a3DWpOFc0.png"
import Button from "react-bootstrap/Button";
import Tuber from "../Assets/images/tuber.png"
import Veg from "../Assets/images/veg.png"
import carrots from "../Assets/images/unsplash_jGqPqGdLqcw.png"
import leaves from "../Assets/images/unsplash_hX_hf2lPpUU.png"
import grain from "../Assets/images/grain.png"
import livestock from "../Assets/images/livestock.png"
import fruit from "../Assets/images/fruit.png"
import Footer from "../Components/footer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const HomePage = () => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    return (
        <motion.div
            className="homepage"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Header activePage={"home"} />
            <motion.img
                src={leaves}
                alt="leaves"
                className="myLeaves"
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            />

            <motion.section
                className="top-holder"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <Container>
                    <motion.div
                        className="topSection my-3"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        <motion.img
                            src={carrots}
                            alt="carrots"
                            className="myCarrot"
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.8, duration: 0.5 }}
                        />
                        <Row>
                            <Col lg="6" className="textss">
                                <motion.div
                                    className="textss wYam"
                                    initial={{ x: -100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 1, duration: 0.5 }}
                                >
                                    <img src={AYam} alt="small yam" className="aYam" />
                                    <h2 className="sec-header">
                                        <span className="colG fw-bold"> Access Agricultural </span>
                                        Products In Large Quantities
                                    </h2>
                                    <div>
                                        <ul>
                                            <li>The online marketplace for surplus farm produce</li>
                                            <li>Get nationwide access to buyers and sellers</li>
                                            <li>
                                                Safe and reliable platform to crowdfund and purchase goods in
                                                bulk.
                                            </li>
                                        </ul>
                                    </div>
                                    {isLoggedIn ? (
                                        <></>
                                    ) : (
                                        <Button
                                            onClick={() => navigate("/signInAs")}
                                            variant="success"
                                            className="mt-5 py-2 px-4 btn-hp"
                                        >
                                            Sign in
                                        </Button>
                                    )}
                                </motion.div>
                            </Col>
                            <Col lg="6">
                                <motion.img
                                    src={TopSecImg}
                                    alt="farm"
                                    className="resImg w-100"
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 1.2, duration: 0.5 }}
                                />
                            </Col>
                        </Row>
                    </motion.div>
                </Container>
            </motion.section>

            <motion.section
                className="category-section"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.4, duration: 0.5 }}
            >
                <Container>
                    <div className="my-5 item-holder">
                        <h2 className="topicWL mb-5">Categories</h2>
                        <div className="items">
                            <Row>
                                <Col sm="4" lg>
                                    <div className="catImg-holder">
                                        <img className="w-100 catIc" src={Tuber} alt="roots and tuber" />
                                        <p>Roots and Tubers</p>
                                    </div>
                                </Col>
                                <Col sm="4" lg>
                                    <div className="catImg-holder">
                                        <img className="w-100 catIc" src={grain} alt="grain" />
                                        <p>Grain</p>
                                    </div>
                                </Col>
                                <Col sm="4" lg>
                                    <div className="catImg-holder">
                                        <img className="w-100 catIc" src={fruit} alt="fruits" />
                                        <p>Fruits</p>
                                    </div>
                                </Col>
                                <Col sm="4" lg>
                                    <div className="catImg-holder">
                                        <img className="w-100 catIc" src={livestock} alt="livestock" />
                                        <p>Livestock</p>
                                    </div>
                                </Col>
                                <Col sm="4" lg>
                                    <div className="catImg-holder">
                                        <img className="w-100 catIc" src={Veg} alt="veg" />
                                        <p>Vegetables</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </motion.section>

            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.6, duration: 0.5 }}
            >
                <Container>
                    <motion.div
                        className="topSection my-5"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 1.8, duration: 0.5 }}
                    >
                        <Row>
                            <Col lg="6">
                                <motion.img
                                    src={AboutUsImg}
                                    alt="farm"
                                    className="resImg w-100"
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 2, duration: 0.5 }}
                                />
                            </Col>
                            <Col lg="6" className="textss">
                                <motion.div
                                    className="textss about"
                                    initial={{ x: -100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 2.2, duration: 0.5 }}
                                >
                                    <h2 className="topicWL mb-5">About Us</h2>
                                    <p className="sec-para">
                                        The BuynBulk platform is a way to connect the farmers and producers straight to the
                                        final consumers through crowdfunding. By doing this, we aim to reduce the supply chain thereby
                                        making prices of goods affordable for the consumers and allowing the farmers to sell their
                                        products at profitable prices. We provide a platform where people from different...
                                    </p>
                                    <Button variant="success"
                                        onClick={() => navigate("/about")}
                                        className="mt-5 py-2 px-4 btn-hpAbt">
                                        Read More
                                    </Button>
                                </motion.div>
                            </Col>
                        </Row>
                    </motion.div>
                </Container>
            </motion.section>

            <motion.div
                className="adjust-height-on-size"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2.4, duration: 0.5 }}
            >
                <img src={Seeds} alt="seeds" className="seeds" />
                <Container>
                    <motion.div
                        className="topSection my-5"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 2.6, duration: 0.5 }}
                    >
                        <Row>
                            <Col lg="6">
                                <motion.img
                                    src={LetsWorkImg}
                                    alt="farm"
                                    className="resImg w-100"
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 2.8, duration: 0.5 }}
                                />
                            </Col>
                            <Col lg="6" className="textss">
                                <motion.div
                                    className="textss text-end"
                                    initial={{ x: -100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ delay: 3, duration: 0.5 }}
                                >
                                    <h2 className="topicWL mb-5">
                                        Are You a Farmer?  Hard work,
                                        low profit? NOT ANYMORE!!!.
                                    </h2>
                                    <p className="sec-para">
                                        Your profit is our priority, we bring you
                                        to the best platform to market and sell
                                        your goods at a profitable price.
                                        {!user && <span>Sign up today and begin to gain the reward for your hard work!</span>}
                                    </p>
                                    {user ? (
                                        <></>
                                    ) : (
                                        <Button variant="success"
                                            onClick={() => navigate("/signInAs")}
                                            className="mt-5 py-2 px-4 btn-hpAbt">
                                            Register
                                        </Button>
                                    )}
                                </motion.div>
                            </Col>
                        </Row>
                    </motion.div>
                </Container>
            </motion.div>

            <Footer />
        </motion.div>
    );
}

export default HomePage;
