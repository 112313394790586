import React from 'react';
import Modal from 'react-modal';import bankCodes from '../Components/data/bankCodes.json';

import './UserModal.css'; // Import your CSS file for modal styling
Modal.setAppElement('#root'); // For accessibility reasons

const UserModal = ({ isOpen, onRequestClose, user }) => {

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const getBank = (code) => {
    if (Array.isArray(bankCodes)) {
      const bank = bankCodes.flatMap(bankCategory => bankCategory.banks)
                            .find(bank => bank.code == code);
      return bank ? bank.name : 'Unknown Bank';
    }
    return 'Unknown Bank';
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Details"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-header">
        <h2>{user?.firstName} {user?.lastName}</h2>
        <button className="close-button" onClick={onRequestClose}>✖</button>
      </div>
      <div className="modal-body">
        <div className="profile-section">
          {user?.avatar && (
            <img src={user.avatar} alt={`${user.firstName} ${user.lastName}`} className="avatar-image" />
          )}
          <div className="user-details">
            <p><strong>Email:</strong> {user?.email}</p>
            <p><strong>Role:</strong> {user?.role}</p>
            <p><strong>Subscription Plan:</strong> {user?.subscriptionPlan || "Expired"}</p>
            <p><strong>Subscription Status:</strong> {user?.subscriptionStatus ? 'Active' : 'Inactive'}</p>
            <p><strong>Verified:</strong> {user?.verified ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <div className="purchased-products">
          <h2>Purchased Products</h2>
          {user?.carts && user.carts.length > 0 ? (
            user.carts.map(cart => (
              <div key={cart._id} className="cart">
                {cart.items && cart.items.length > 0 ? (
                  <div className="cart-items">
                    {cart.items.map(item => (
                      <div key={item._id} className="cart-item">
                        <div className="cart-item-image">
                          <img src={item.product.images[0]} alt={item.product.name} />
                        </div>
                        <div className="cart-item-details">
                          <h3>{item.product.name}</h3>
                          <p>{item.product.description}</p>
                          <p>Amount Paid: {formatCurrency(item.amountPaid)}</p>
                          <p>Contribution Percentage: {item.contributionPercentage}%</p>
                          <p>Payment Status: {item.paymentStatus}</p>
                          <p>Payment Verified: {item.paymentVerified ? 'Yes' : 'No'}</p>
                          <p>Received: {item.received ? 'Yes' : 'No'}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No Purchased Items Yet.</p>
                )}
              </div>
            ))
          ) : (
            <p>No product  found.</p>
          )}
        </div>
        <div className="account-info">
          {user?.account && user.account.accountName ? (
            <div className="account-details">
              <div>
                <p><strong>Account Name:</strong> {user.account.accountName}</p>
                <p className="account-number"><strong>Account Number:</strong> {user.account.accountNumber}</p>
                <p><strong>Bank Name:</strong> {getBank(user.account.bankName)}</p>
              </div>
            </div>
          ) : (
            <p>No account information available.</p>
          )}
        </div>
        <div className="campaigns-info">
          <h2>Product Campaigns</h2>
          {user?.products && user.products.length > 0 ? (
            user.products.map(product => (
              <div key={product._id} className="product-campaign">
                <h3>{product.productName}</h3>
                {product.campaigns && product.campaigns.length > 0 ? (
                  product.campaigns.map(campaign => (
                    <div key={campaign._id} className="campaign-details">
                      <div className="campaign-image">
                        {campaign.product && campaign.product.productImages && campaign.product.productImages.length > 0 && (
                          <img src={campaign.product.productImages[0]} alt={campaign.product.productName} />
                        )}
                      </div>
                      <div className="campaign-info">
                        <h4>Campaign Details</h4>
                        <p><strong>Status:</strong> {campaign.isActive ? 'Active' : 'Inactive'}</p>
                        <p><strong>Goal Amount:</strong> {formatCurrency(campaign.goalAmount)}</p>
                        <p><strong>Current Amount:</strong> {formatCurrency(campaign.currentAmount)}</p>
                        <p><strong>Start Date:</strong> {new Date(campaign.startDate).toLocaleDateString()}</p>
                        <p><strong>End Date:</strong> {campaign.endDate ? new Date(campaign.endDate).toLocaleDateString() : 'Ongoing'}</p>
                        <p><strong>Percentage Funded:</strong> {campaign.currentAmount /campaign.goalAmount* 100}%</p>
                        <div className="contributions">
                          <h5>Contributions</h5>
                          {campaign.contributions && campaign.contributions.length > 0 ? (
                            campaign.contributions.map(contribution => (
                              <div key={contribution._id} className="contribution-details">
                                <p><strong>Contributor:</strong> {contribution.participant.email}</p>
                                <p><strong>Amount Paid:</strong> {formatCurrency(contribution.amountPaid)}</p>
                              </div>
                            ))
                          ) : (
                            <p>No contributions yet.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No campaigns for this product.</p>
                )}
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UserModal;
